import { useGSAP } from '@gsap/react';
import { Box, Typography } from '@mui/material'
import gsap, { snap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';
// import VerticalScroll from './VerticalScroll';
import { stagger } from 'framer-motion';
import ContactUs from './ContactUs';
import { useTheme, useMediaQuery } from '@mui/material';


gsap.registerPlugin(ScrollTrigger);


const Advertise = () => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    useGSAP(() => {

    // const photos = gsap.utils.toArray(".photo:not(:first-child)");
    // const allPhotos = gsap.utils.toArray(".photo")
    // const details = gsap.utils.toArray(".content:not(:first-child)");
  //  gsap.set(photos, {yPercent:101})

    const details = gsap.utils.toArray(".content:not(:first-child)")
    const photos = gsap.utils.toArray(".photo:not(:first-child)")
    const allPhotos = gsap.utils.toArray(".photo")


    gsap.set(photos, {yPercent:101})


        ScrollTrigger.create({
            trigger:".contain",
            start:"top 270px",
            end:"bottom bottom",
            pin:".imgSection",
            markers:false,
            scrub:false
        })

        // details.forEach((detail, index) => {
        //     let headline = detail.querySelector("h2")
        //     let animation = gsap.timeline()
        //             .to(photos[index], {yPercent:0})
        //             .set(allPhotos[index], {autoAlpha:0})
        //     ScrollTrigger.create({
        //         trigger:headline,
        //         start:"top 30%",
        //         end:"top 40%",
        //         animation:animation,
        //         scrub:true,
        //         markers:false

        //     })
        // })

        details.forEach((detail, index)=> {

            let headline = detail.querySelector("p")
            let animation = gsap.timeline()
               .to(photos[index], {yPercent:0})
               .set(allPhotos[index], {autoAlpha:0})
            ScrollTrigger.create({
                trigger:headline,
                start:"top 90%",
                end:"top 30%",
                animation:animation,
                scrub:1,
                markers:false
            })
        })

    },[])


    // let tl = gsap.timeline({
    //     scrollTrigger:{
    //         trigger:".content",
    //         toggleActions:"restart pause reverse pause",
    //         start:"top center",
    //         end: "+=900",
    //         markers:true,
    //         scrub:true,
    //         pin:true
    //     },
    // })

    // useGSAP(() => {

    //     gsap.to(".imgSection", {
    //         scrollTrigger:{
    //         trigger:".image",
    //         start:"top top",
    //         end:"bottom bottom",
    //         pin:"imgSection",
    //         markers:true
    //         },

    //     })   
    //  },)

  return (
    <Box className="advertise" sx={{  height:"100vh", marginTop:"5rem" , width:"100%" }} >

        <Box sx={{ display:'flex',  justifyContent:"space-evenly", mt:5  }} >
            {
                isXs ? null : 
            <Box className="image" sx={{ width:"50%" }} >
            <Box className="imgSection" sx={{ position:"relative", width: "30rem", height: "20rem", ml:10, overflow:"hidden", borderRadius:3 }} >

            <Box component="img" className='photo' src="https://cdn2.picryl.com/photo/2018/05/11/wyatt-whelan-left-and-eric-roberts-both-posigen-12ff63-1024.jpg"                    sx={{ position:"absolute", width:"100%", height:'100%', boxShadow:"0px 5px 6px 2px #b39569" }} />
            <Box component="img" className='photo' src="https://images.prismic.io/palmettoblog/7e3ed162-a9b4-4f27-b953-df7b7f68ccf3_solar-installer-large.jpeg?auto=compress,format" sx={{ position:"absolute", width:"100%", height:'100%', boxShadow:"0px 5px 6px 2px #b39569" }} />
            <Box component="img" className='photo' src="https://i1.pickpik.com/photos/857/346/332/solar-panels-installation-workers-array-preview.jpg"                               sx={{ position:"absolute", width:"100%", height:'100%', boxShadow:"0px 5px 6px 2px #b39569" }} />
            {/* <Box component="img" className='photo' src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/home-improvement/wp-content/uploads/2022/07/Untitled-design-2021-08-18T120552.419.png" sx={{ position:"absolute", top:0, left:0, zIndex: 2, width:"100%", height:'100%', objectFit:'cover' }} /> */}

            </Box>
            </Box>
            }
            

            <Box className="contain" sx={{ width: isXs ? "100%" : "50%",  display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >

            <Box className="content" sx={{ width:"90%" }} >
                <h2 style={{ fontFamily:"montserrat", fontWeight:700, fontSize:"1.8rem", color:"#0F67B1", textTransform:"capitalize" }} >Who we are!</h2>
                <Typography sx={{ color:"#3FA2F6", fontFamily:"Poppins", fontWeight:500, fontSize: isXs ? "1.1rem" : "1.3rem", mt:1 }} >Your Reliable Solar Installation Partner.</Typography>
                <p style={{ color:"#545454", fontFamily:"Poppins", fontWeight:400, mt:1, fontSize: isXs ? "0.8rem" : "1rem" }} >
                At Ranapay, we are dedicated to illuminating your life with the power of the sun. As industry leaders in solar power plant installation, we combine 
                cutting-edge technology with unparalleled expertise to deliver sustainable energy solutions that are both reliable and affordable. Our extensive network 
                and widespread reach ensure that no matter where you are, our services are just a call away. We prioritize your satisfaction and peace of mind, providing
                 customized solar solutions that meet your unique needs and significantly reduce your energy costs. With Ranapay, you're not just investing in solar power; 
                 you're partnering with a trusted ally committed to a brighter, cleaner, and more sustainable future for all.
                </p>
               
            </Box>

            <Box className="content" sx={{ width:"90%", mt:"6rem" }} >
            <h2 style={{ fontFamily:"montserrat", fontWeight:700, fontSize:"1.8rem", color:"#0F67B1", textTransform:"capitalize" }} >What we do!</h2>
            <Typography sx={{ color:"#3FA2F6", fontFamily:"Poppins", fontWeight:500,  fontSize: isXs ? "1.1rem" : "1.3rem", mt:1 }} >Leading the Way in Solar Solutions.</Typography>
                <p style={{ color:"#545454", fontFamily:"Poppins", fontWeight:400, mt:1, fontSize: isXs ? "0.8rem" : "1rem" }} >
                At Ranapay, we are dedicated to illuminating your life with the power of the sun. As industry leaders in solar power plant installation, we combine 
                cutting-edge technology with unparalleled expertise to deliver sustainable energy solutions that are both reliable and affordable. Our extensive network 
                and widespread reach ensure that no matter where you are, our services are just a call away. We prioritize your satisfaction and peace of mind, providing
                 customized solar solutions that meet your unique needs and significantly reduce your energy costs. With Ranapay, you're not just investing in solar power; 
                 you're partnering with a trusted ally committed to a brighter, cleaner, and more sustainable future for all.
                </p>
            </Box>

            {
                isXs ? null : 
            <Box className="content" sx={{ width:"90%", mt:'6rem' }} >
            <h2 style={{ fontFamily:"montserrat", fontWeight:700, fontSize:"1.8rem", color:"#0F67B1", textTransform:"capitalize" }} >What's our goal!</h2>
            <Typography sx={{ color:"#3FA2F6", fontFamily:"Poppins", fontWeight:500, fontSize:"1.3rem", mt:1 }} >Brighten Your Life with Ranapay solar energy.</Typography>
                <p style={{ color:"#545454", fontFamily:"Poppins", fontWeight:400, mt:1, fontSize:"1rem" }} >
                At Ranapay, we are dedicated to illuminating your life with the power of the sun. As industry leaders in solar power plant installation, we combine 
                cutting-edge technology with unparalleled expertise to deliver sustainable energy solutions that are both reliable and affordable. Our extensive network 
                and widespread reach ensure that no matter where you are, our services are just a call away. We prioritize your satisfaction and peace of mind, providing
                 customized solar solutions that meet your unique needs and significantly reduce your energy costs. With Ranapay, you're not just investing in solar power; 
                 you're partnering with a trusted ally committed to a brighter, cleaner, and more sustainable future for all.
                </p>
            </Box>
            }


    
            </Box>
        </Box>

        <ContactUs/>
    </Box>
)
}

export default Advertise