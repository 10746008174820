import { Alert, AppBar, Box, Button, Divider, LinearProgress, Paper, Snackbar, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import logo from "../images/Withe.png"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ranaLogo from "../images/Without-name.png"
import CloseIcon from '@mui/icons-material/Close';
import login from "../images/login.png";
import register from "../images/rgistration.png";
import { ApiHandler } from './ApiHandler';
import { useNavigate } from 'react-router-dom';
import verifyNumber from "../images/verifyNumber.png";
import consumerImg from "../images/consumerKeyImg.png";
import Content2 from './Content2';
import Advertise from './Advertise';
import ContactUs from './ContactUs';
import { useTheme, useMediaQuery } from '@mui/material';





function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };

}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
});

const Landing = () => {

  const [ mousePosition , setMousePosition] = React.useState({
    x:0,
    y:0
  });

  const [value, setValue] = React.useState(0);
  const [openReg, setOpenReg] = React.useState(false);
  const [ openLogin , setOpenLogin ] = React.useState(false);
  const [ loginNumber , setLoginNumber ] = React.useState("");
  const [ isMobile , setIsMobile ] = React.useState(false);
  const [ loginOtp , setLoginOtp ] = React.useState("");
  const [ openOtp , setOpenOtp ] = React.useState(false);
  const [ otpValue , setOtpValue ] = React.useState("");
  const [snackRightOpen, setSnackRightOpen ] = React.useState(false);
  const [snackMessage , setSnackMessage ] = React.useState('');
  const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
  const [snackEError , setSnackEError] = React.useState('');
  const [ progress, setProgress ] = React.useState(false);
  const [ regProgress , setRegProgress ] = React.useState(false);
  const [ enableRegSubmit , setEnableRegSubmit ] = React.useState(false);
  const [ sessionId , setSessionId ] = React.useState("");
  const [ regName , setRegName ] = React.useState("");
  const [ regEmail , setRegEmail ] = React.useState("");
  const [ regMobile , setRegMobile ] = React.useState("");
  const [ regKName , setKName ] = React.useState("");
  const [ enableKNo , setEnableKNo ] = React.useState(false);
  const  [ sendingOtp , setSendingOtp ] = React.useState(false);
  const [ activeRegNum , setActiveRegNum] = React.useState(false);

  const [ regNumProg , setRegNumProg ] = React.useState(false);
  const [ regConKeyProg , setRegConsKeyProg] = React.useState(false);
  const [ loginOpenOtp , setLoginOpenOtp ] = React.useState(false);
  const  [ loginOtptoken , setLoginOtpToken ] = React.useState("");

  const [ signUp , setSignUp ] = React.useState({
    name:"",
    mobileNumber:"",
    email:"",
    consumerNumber:""
  }) 

  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleLoginOtpClose = () => {
    setLoginOpenOtp(false);
  }


  const handleRightSnackEClose = () => {
    setSnackRightEOpen(false);
  }


  const handleRightSnackClose = () => {
    setSnackRightOpen(false)
}

const handleOtpClose = ( )=> {
  setOpenOtp(false);
}

  const handleSendingOtpClose = ( )=> {
    setOpenOtp(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenReg(false);
  };

//   React.useEffect(() => {
//     const trailerElement = document.getElementById('trailer');
//     const solarElement = document.querySelector('.solar');

//     const handleMouseMove = (e) => {
//         const x = e.clientX - trailerElement.offsetWidth / 2;
//         const y = e.clientY - trailerElement.offsetHeight / 2;

//         const keyFrame = {
//             transform: `translate(${x}px, ${y}px)`,
//         };

//         trailerElement.animate(keyFrame, {
//             duration: 300,
//             fill: 'forwards',
//         });
//     };

//     const handleMouseOver = () => {
//         trailerElement.style.backgroundColor = 'black';
//         trailerElement.style.transform = 'scale(6)';
//     };

//     const handleMouseOut = () => {
//         trailerElement.style.backgroundColor = ''; // Reset to original color
//         trailerElement.style.transform = 'scale(1)'; // Reset to original scale
//     };

//     window.addEventListener('mousemove', handleMouseMove);
//     solarElement.addEventListener('mouseover', handleMouseOver);
//     solarElement.addEventListener('mouseout', handleMouseOut);

//     // Cleanup event listeners on component unmount
//     return () => {
//         window.removeEventListener('mousemove', handleMouseMove);
//         solarElement.removeEventListener('mouseover', handleMouseOver);
//         solarElement.removeEventListener('mouseout', handleMouseOut);
//     };
// }, []);

  const handleOtpOpen = () => {
    setOpenLogin(false);
    setOpenOtp(true);
  }

  const handleFieldChange = () => {
    
  }

  const handleMobileLogin = async() => {

    setRegNumProg(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verify_phone` ;
    const method = "POST";
    const bodyData = {"phone_no":regMobile }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("login json : ",json);

    setRegNumProg(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setEnableKNo(true);
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }

  React.useEffect(() => {
    if(regMobile.length === 10){
     
      handleMobileLogin()

    }else{
      console.log("target miss");
    }
  },[regMobile])



  const handleName = (e) => {
    if(e){
      setRegName(e.target.value);
    }
    if(e.target.value?.length === 0 ){
      setRegName("");
      setRegEmail("");
      setRegMobile("");
      setEnableKNo(false)
      setKName("");
      setEnableKNo(false);
    }
  }


  const handleEmail = (e) => {
    if(e){    
    setRegEmail(e.target.value);

    const trimmedEmail = String(e.target.value)?.trim();
    const emailRegex =/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValid = emailRegex.test(trimmedEmail);
    console.log("isValid : ",isValid)
    if(isValid === true ){
      setActiveRegNum(true)
    }else if(isValid === false) {
      setActiveRegNum(false);
      setRegMobile("");
    }else{
      setActiveRegNum(false);
      setRegMobile("");
    }

      //console.log(isValid); // Log true if valid, false if not
      // return isValid;

    }
    if(e.target.value.length === 0){
      setRegMobile("");
      setEnableKNo(false)
      setKName("");
      setEnableKNo(false);
    }
  }

  const handleMobile = (e) => {
    if(e){
      setRegMobile(e.target.value)
    }
    if(e.target.value?.length !== 10 ){
      setKName("");
      setEnableKNo(false)
      setEnableKNo(false);
    }
  }

  const handleKName = (e) => {
    if(e){
      setKName(e.target.value);
    }
  }

  const handleConsumer = async() => {

    setRegConsKeyProg(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verify_consumerKey` ;
    const method = "POST";
    const bodyData = {"consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("login json : ",json);

    setRegConsKeyProg(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setEnableKNo(true);
        setEnableRegSubmit(true);
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }


  }

  React.useEffect(() => {
    if(regKName.length === 10 ){
      console.log("mission accomplish");
      handleConsumer();
      return
    }else{
      console.log("mission pending");
      setEnableRegSubmit(false);
    }
  },[regKName])


  const verifyOtp = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verifyOtp` ;
    const method = "POST";
    const bodyData = { "phone_no":regMobile, "consumer_key":regKName, "otp":otpValue, "sessionId":sessionId }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    setProgress(false);

    console.log("verify otp json : ",json);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          navigate("/dashboard", { state : { data : json.data.access_token }});
        },1000)
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }
    
  }


  const getRegOtp = async() => {

    setRegProgress(true)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/getOtp` ;
    const method = "POST";
    const bodyData = { "name":regName, "email":regEmail, "phone_no":regMobile, "consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("receive otp json : ",json);

    setRegProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setSendingOtp(false);
        setOpenOtp(true);
        setSessionId(json.data.session_Id);
        // setTimeout(() => {
        //   navigate("/dashboard")
        // },1000)


      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }

  const registerUser = async() => {

    setRegProgress(true)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/register` ;
    const method = "POST";
    const bodyData = { "name":regName, "email":regEmail, "phone_no":regMobile, "consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    setRegProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setOpenReg(false);
        setSendingOtp(true);
        getRegOtp();

        return;

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }
  }

  const handleLogin = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/login` ;
    const method = "POST";
    const bodyData = { "phone_no":loginNumber }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setOpenLogin(false);
        setLoginOpenOtp(true);
        setSessionId(json.data.session_Id);
        setLoginOtpToken(json?.data.login_token);

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

    
  }

  React.useEffect(() => {
    if(loginNumber.length === 10){
      handleLogin();
    }
    else{
      return
    }
  },[loginNumber])


  const verifyLoginOtp = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verifyLoginOtp` ;
    const method = "POST";
    const bodyData = { "phone_no":loginNumber, "otp":loginOtp, "sessionId":sessionId}

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":loginOtptoken });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          navigate("/dashboard", { state : { data : json.data.access_token }} )
        },[500])
        

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }
  
  console.log("window location : ",window.location.search);

  const validtoken = async(e) => {

    console.log("entered agent function");

    try{

      const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/validateAgentToken` ;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "authAccess":e, bodyData:{} });

    console.log("validate json data : ",json);

    if(json.status === "success"){
      navigate("/agentDashboard", {state : {date : json.data}}, {replace:true});
    }

    if(json.status === "error" ){
      setSnackRightEOpen(true);
      setSnackEError(json.message); 
       }


    }catch(err){
      console.log("validate agent : ",err);
      setSnackRightEOpen(true);
      setSnackEError(err);
    }

    
  }

  React.useEffect(() => {
    if(window.location.search){
      console.log("entered search location ");

      const tokenData = window.location.search.replace("?home=", "");

      console.log("token data : ",tokenData);

      validtoken(tokenData);


    }
    else if(!window.location.search){
      console.log("no window location search");
    }
  },[])


  return (
    <>
    <Box className="landing" >
      <div id='trailer' >
      </div>
      {/* <AppBar position='static' sx={{ backgroundColor:'#edd153', borderRadius:"0 0px 15px 15px",   }} > */}
      <AppBar position='static' sx={{ backgroundColor:'#5ca8db', borderRadius:"3rem", mt:1, width:"98%"  }} >

        <Toolbar sx={{ display:'flex', alignItems:'center', p:0.5, gap:0 }} >
          <Box component="img" src={logo} sx={{ width:{lg:"5rem", xs:"3rem"}, marginLeft: isXs ? 1 : 0  }} />
          <Typography  sx={{ fontFamily:"montserrat", fontWeight:600, color:'black', fontSize:{lg:'1.2rem', xs:"0.8rem"},position:"absolute", right:0, marginRight:"1.2rem", color:"FAFFAF" }} >Rana Solar</Typography> 
        </Toolbar>
      </AppBar>
      <Typography sx={{ fontFamily:"Ubuntu Sans", fontWeight:600, textAlign:'center', fontSize:{lg:"4.5rem", xs:"1.9rem", md:"4.5rem", sm:"3.2rem" }, color:'white', marginTop:{lg:"5rem", xs:"5rem"} }} >  Power Up Society With  </Typography>
      <Typography className='solar' sx={{ fontFamily:"Grechen Fuemen", fontWeight:600, textAlign:'center', fontSize:{lg:"4rem", sm:'3rem', md:"4rem", xs:"2rem" } , color:'white', marginTop:{lg:"-1rem", md:0, xs:0 } }} >   <span style={{ color:"#0F67B1" , fontSize: "5.5rem", textShadow:"0px 1px 3px #fcffd4", lineHeight: isXs ? "-2rem" : null }} > Solar Energy </span>   </Typography>
      {/* <Typography sx={{ fontFamily:"montserrat", fontWeight:400, textAlign:'center', fontSize:{ lg:"1rem", sm:"0.9rem", xs:"0.8rem", md:"0.9rem" }, color:'#fcffd4', marginTop:"2rem", textTransform:'capitalize' }} >  Let's light up our lives while protecting the planet for a brighter and cleaner future.</Typography> */}

      <Box sx={{ marginTop: isXs ? '5rem':'7rem', display:'flex', alignItems:'center', justifyContent:'center', gap:5 }} >
        <Button variant='contained' onClick={() => setOpenLogin(true)} endIcon={ <LoginIcon/> } sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"1rem" }} >Login</Button>
        <Button variant='outlined' onClick={() => setOpenReg(true)} endIcon={ <VerifiedUserIcon/> } sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"1rem", color:'white', border:'1px solid white' }} >Register</Button>

      </Box>

     
     
  
{/* *********************** Registration Dialogue ******************************* */}


      <Dialog
        open={openReg}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50rem",  // Set your width here
            },
          },
            zIndex:1500
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Register Please </Typography>
          
          <CloseIcon onClick={() => setOpenReg(false)} sx={{ backgroundColor:'#e03636',fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" } , borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:'center', flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }}} >
            <Box sx={{display:"flex", alignItems:'center' ,justifyContent:'center', flexDirection:'column'  }} >
              {
                regNumProg ? <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:'1.2rem', textTransform:"capitalize" }} > verifying number </Typography> : regConKeyProg ? 
                <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:'1.2rem', textTransform:"capitalize" }} > verifying consumer key </Typography>  : null

              }
            <Box component="img" src={ regNumProg ?  verifyNumber :  regConKeyProg ? consumerImg : register} sx={{ width:{lg:"25rem", xs:"12rem", sm:"22rem", md:"25rem" } }} />
            </Box>

          <Box sx={{ display:'grid', gridTemplateColumns:'repeat(1,1fr)', rowGap:2, columnGap:3, alignItems:'center', justifyContent:'center' }} >

            <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Name*</label> */}
              <TextField onChange={(e) => handleName(e) } value={ regName }  inputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Name*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>

              
              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Email</label> */}
              <TextField disabled={ !regName }  onChange={(e) => handleEmail(e)} value={ regEmail } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Email*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>

              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Mobile Number*</label> */}
              <TextField disabled={ activeRegNum === false || regEmail.length === 0 } onChange={(e) => handleMobile(e)} value={ regMobile } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Mobile Number*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>


              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter consumer Number</label> */}
              <TextField disabled={ enableKNo === false } onChange={(e) => handleKName(e)} value={ regKName } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter consumer Number*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>
          <Button onClick={() => registerUser()} size='large' disabled={ !regName || !regEmail || !regMobile || !regKName || enableRegSubmit !== true || regProgress }  variant='contained' sx={{ fontFamily:"Montserrat", mt:2, width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} >{regProgress ? "submitting data.." : "submit data" }</Button>
          </Box>
          </Box>
        </DialogContent>
      
      </Dialog>



      {/* *********************** Enter Mobile Login Dialogue ******************************* */}




      <Dialog
        open={openLogin}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenLogin(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{  width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
                  <label style={{ fontFamily:"Poppins", fontSize:'0.8rem', textTransform:"capitalize", color:"#7d7d7d" }} >Enter registered Mobile Number*</label>
                  <TextField type='number' value={loginNumber} onChange={(e) => setLoginNumber(e.target.value)} sx={{ width:"15rem", marginTop:1,  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "input[type=number]": {
          MozAppearance: "textfield",
        } }} variant='outlined' />   
              {
                progress ? <LinearProgress sx={{ width:"15rem", marginTop:1 }} /> : null
              }
                </Box>
              {/* <Button onClick={() => handleMobileLogin()} disabled={ loginNumber.length !== 10 || progress } sx={{ marginTop:2,  width:"15rem" }} variant='contained' size='small' fullWidth >{progress ? "Loading.." : "Submit"}</Button> */}
            </Box>
          

        </DialogContent>

      
      </Dialog>


{/* *********************** Register OTP Dialogue ******************************* */}





      <Dialog
        open={openOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{ width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
                  <label style={{ fontFamily:"Poppins", fontSize:'0.8rem', textTransform:"capitalize", color:"#7d7d7d" }} >Enter OTP Send To Register Number*</label>
                  <TextField value={ otpValue } onChange={(e) => setOtpValue(e.target.value)} sx={{ width:"15rem", marginTop:1 }} variant='outlined' />   
                {
                  progress ? <LinearProgress sx={{ marginTop:2,  width:"15rem" }} /> : 
              <Button onClick={() => verifyOtp()} disabled={ otpValue.length < 6 } sx={{ marginTop:2,  width:"15rem" }} variant='contained' size='small' fullWidth >Submit</Button>
                }
                </Box>
            </Box>
          

        </DialogContent>
      
      </Dialog>



    {/* **************************** Login OTP Dialogue ********************************** */}



      <Dialog
        open={loginOpenOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleLoginOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" }}}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setLoginOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{  width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
                  <label style={{ fontFamily:"Poppins", fontSize:'0.8rem', textTransform:"capitalize", color:"#7d7d7d" }} >Enter OTP Send To Register Number*</label>
                  <TextField value={ loginOtp } type='number' onChange={(e) => setLoginOtp(e.target.value)} sx={{ width:"15rem", marginTop:1,  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "input[type=number]": {
          MozAppearance: "textfield",
        } }} variant='outlined' />   
                {
                  progress ? <LinearProgress sx={{ marginTop:2,  width:"15rem" }} /> : 
              <Button onClick={() => verifyLoginOtp()} disabled={ loginOtp.length < 6 } sx={{ marginTop:2,  width:"15rem" }} variant='contained' size='small' fullWidth >Submit</Button>
                }
                </Box>
            </Box>
          

        </DialogContent>
      
      </Dialog>



    {/* ********************************** Sending otp Dialogue **************************** */}


    <Dialog
        open={sendingOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSendingOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{  width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box>
                  <label style={{ fontFamily:"Poppins", fontSize:'0.7rem', textTransform:"capitalize", color:"#7d7d7d" }} >Sending OTP to Your Regitered Number</label>
                    <LinearProgress fullWidth sx={{ marginTop:1 }} />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>



      <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightOpen}
                onClose={handleRightSnackClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackClose} severity='success' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackMessage}</Alert> 
            </Snackbar>  
            

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightEOpen}
                onClose={handleRightSnackEClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackEClose} severity='error' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackEError}</Alert> 
            </Snackbar> 

            


    </Box>

    <Box sx={{   display:'flex', alignItems:'center', justifyContent:"center" , width:"100%", flexDirection:'column' }} >
    <Content2 />
    <Advertise/>
    </Box>


    </>
  )
}

export default Landing