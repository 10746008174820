import { Box } from '@mui/material'
import React from 'react'
import Landing from './Landing'
import Content2 from './Content2'
import Advertise from './Advertise'
import ContactUs from './ContactUs'

const LandingFirst = () => {
  return (
    <Box>
        <Landing/>
        
        </Box>
  )
}

export default LandingFirst