import React from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DashBoard from './component/DashBoard';
import LandingFirst from './component/LandingFirst';
import AgentDashBoard from "./component/AgentDashBoard";
function App() {
  return (
    <BrowserRouter basename='/solarui' >
    <Routes>      
      <Route path="/" element={<LandingFirst />} />
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/agentDashboard" element={ <AgentDashBoard/> } />
    </Routes>
  </BrowserRouter>
  );
}

export default App;

