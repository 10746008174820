import React from 'react';
import { Alert, Box, Button, Divider, LinearProgress, Paper, Snackbar, Typography } from '@mui/material';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, user, Image } from "@nextui-org/react";
import {Tabs, Tab, Card, CardBody, Badge} from "@nextui-org/react";
import logo from "../images/With-Name.png"
import {Input} from "@nextui-org/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiHandler } from './ApiHandler';
import {Select, SelectSection, SelectItem} from "@nextui-org/select";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme, useMediaQuery } from '@mui/material';

const DashBoard = () => {

  const [ userDetails , setUserDetails ] = React.useState({
    name:"",
    installation_address:"",
    address:"",
    village:"",
    city:"",
    district:"",
    discom:"",
    state:"",
    pin:"",
    csc_vle:"",
    csc_vleId:""
  });

  console.log("user detals : ",userDetails.district);

  const [ companyDetails ,setCompanyDetails ] = React.useState({
    distribution_company:"",
    division:"",
    subDivision:"",
    consumerNo:"",
    load:""
  });

  const [ rooftopData , setRooftopData ] = React.useState({
    state:"",
    category:"",
    plant_capacity:"",
    existing_capacity:"",
    latitude:"",
    longitude:""
  });

  const [ contactDetails , setContactDetails ] = React.useState({
    phone_no:"",
    email:"",
    phone_no2:""
  })

  const [ companyDetail , setCompanyDetail ] = React.useState(false);

  const [snackRightOpen, setSnackRightOpen ] = React.useState(false);
  const [snackMessage , setSnackMessage ] = React.useState('');
  const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
  const [snackEError , setSnackEError] = React.useState('');

  const [ submitStage1 , setSubmitStage1 ] = React.useState(false);
  const [ rooftopSubmit , setRooftopSubmit ] = React.useState(false);
  const [ contactSubmit , setContactSubmit ] = React.useState(false);
  const [ companyDetailSubmit , setCompanyDetailSubmit ] = React.useState(false);

  const [emptyField, setEmptyField] = React.useState([]);
  const [ emptyCompanyData , setEmptyCompanyData ] = React.useState([]);
  const [ emptyRooftopData , setEmptyRooftopData ] = React.useState([]);
  const [emptyContactDetails , setEmptyContactDetails ] = React.useState([]);

  const [ userDetailBadge , setUserDetailbadge ] = React.useState(false);
  const [ contactDetailBadge , setContactDetailBadge ] = React.useState(false);
  const [ roofDetailbadge , setRoofDetailbadge ] = React.useState(false);
  const [ companyDetailBadge , setCompanyDetailbadge ] = React.useState(false);

  const  [ stateData , setStateData ] = React.useState();
  const [ stateCode , setStateCode ] = React.useState("");

  const [ discomData , setDiscomData ]  = React.useState();
  const  [ shortDist , setShortDist ] = React.useState("");

  const [ districtData , setDistrictData ] = React.useState();
  const [  districtCode , setDistrictCode ] =React.useState('');

  const [ formData , setFormData ] = React.useState();

  const [ progress , setProgress ] = React.useState(false);

  const [ formStatus , setFormStatus ] = React.useState();
  const [ pendingStage, setPendingStage ] = React.useState();

  const  [ formRowRespo , setFormRowResp ] = React.useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const accessToken = location.state?.data;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));  

  console.log("location data : ",accessToken);

  const handleRightSnackEClose = () => {
    setSnackRightEOpen(false);
  }

  const handleRightSnackClose = () => {
    setSnackRightOpen(false)
}

  const handleFormChange = (e) => {

    const { name , value } = e.target;

    console.log("handle Form change : ", e.target );

    setSubmitStage1(false);

    setUserDetails(prevState => ({
      ...prevState,
      [ name ]:value
    }));
  };


  const handleRooftop = (e) => {
    const {name , value} = e.target;

    setRooftopSubmit(false);

    setRooftopData(prevState => ({
      ...prevState,
      [ name ]: value
    }));
  }


  const handleCompanyDetails = (e) => {
    const {name , value} = e.target;
    setCompanyDetailSubmit(false);
    setCompanyDetails(prevState => ({
      ...prevState,
      [name]:value
    }));
  }

  const handleContact = (e) => {
    const {name , value} = e.target;
    setContactSubmit(false);
    setContactDetails(prevState => ({
      ...prevState,
      [name]:value
    }));
  }





  // const  = true; // Example static value
  // const shouldDisableVideos = false; // Example static value

  // // Determine which tabs should be disabled
  // const disabledKeys = [];
  // if (shouldDisableMusic) {
  //   disabledKeys.push("music");
  // }
  // if (shouldDisableVideos) {
  //   disabledKeys.push("videos");

  // }

  const findEmptyField = () => {
    const emptyFields = Object?.entries(userDetails)
    .filter(([key , value]) => 
(     typeof value === 'string' ? value.trim() === '' : !value
)     )
    .map(([key]) => key);
    setEmptyField(emptyFields);
    return emptyField.length > 0
  }

  const findEmptycompany = () => {
    const emptyFields = Object.entries(companyDetails)
    .filter(([key , value]) => value?.trim() === '' )
    .map(([key]) => key);
    setEmptyCompanyData(emptyFields);
    return emptyField.length > 0
  }

  const emptyRooftop = () => {
    const emptyFields = Object.entries(rooftopData)
    .filter(([key , value]) => value?.trim() === '' )
    .map(([key]) => key);
    setEmptyRooftopData(emptyFields);
    return emptyField.length > 0
  }

  const emptyContact = () => {
    const emptyFields = Object.entries(contactDetails)
    .filter(([key , value]) => value?.trim() === '' )
    .map(([key]) => key);
    setEmptyContactDetails(emptyFields);
    return emptyField.length > 0
  }

  console.log("empty details : ",emptyContactDetails);

  React.useEffect(() => {
    findEmptyField()
    // window.alert("Entered Dashboard");
  },[userDetails])

  React.useEffect(() => {
    findEmptycompany()
  },[companyDetails])

  React.useEffect(() => {
    emptyRooftop()
  },[rooftopData]);

  React.useEffect(() => {
    emptyContact();
  },[contactDetails]);

  const submitApplicationForm = async() => {

    setProgress(true);
  
      console.log("user detail : ",userDetails);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/applicantForm` ;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":userDetails , "authAccess":accessToken });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setCompanyDetail(true);
        setUserDetailbadge(false);

        setTimeout(() => {
          getFormStatus()
        },[])
        

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }
      
    
  };

  console.log("empty field : ",emptyField );

  const submitCompanyData = async() => {

    setProgress(true);

    console.log("company details ****** : ",companyDetail)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/companyDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":companyDetails , "authAccess":accessToken });

    console.log("login json data : ",json);

    setProgress(false);

    
    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          getFormStatus()
        },[500])
        

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }


  const submitRooftopData = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/rooftopDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":rooftopData , "authAccess":accessToken });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          getFormStatus()
      },[500])
    }

    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message);
    }
    }

  }

  const submitContactDetails = async() => {

    setProgress(true);

    console.log("contact details : ",contactDetails)


    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/contactDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "bodyData":contactDetails, "method":method, "authAccess":accessToken });

    console.log("form status : ",json);

    setProgress(false);

    if(json){
      
      if(json.Status === "Success"){
        setSnackRightOpen(true);
        setSnackMessage(json.Message);

        setTimeout(() => {
          getFormStatus()
      },[])
    }

    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message);
    }
    }
    

  }

  console.log("form status : ",formStatus , "  pending stage : ",pendingStage);

  const formRow = async() => {

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/getFormRow`;
    const method = "GET";

    const json = await ApiHandler({"apiUrl":api, "method":method, "authAccess":accessToken });

    console.log("form status data : ",json);

    if(json){
      if(json.status === "success" ){
          //setFormRowResp(json.data);
          getFormStatus(json.data[0]?.form_id)
        }
      }
      
    }
    // console.log("json form row : ",formRowRespo[0].form_id);

  React.useEffect(() => {
    formRow()
  },[])

  const getFormStatus = async(e) => {

    console.log("form row data prop : ",e);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/getFormData`;
    const method = "POST";
    const bodyData ={ "form_id":`${e}`} 


    const json = await ApiHandler({"apiUrl":api, "bodyData":bodyData , "method":method, "authAccess":accessToken });

    console.log("form status data : ",json);

    if(json){

      if(json.status === "success"){
        setFormData(json.data.app_details);
        setFormStatus(json.data?.form_status);
        setPendingStage(json.data?.pending_stage);

      }

      if( json.data?.app_details[0].stage === 1 ){
        setSubmitStage1(true)
        setUserDetailbadge(true);
        const data = json.data?.app_details[0].stage_data;
     //   setFormData(json.data?.app_details);
      
        console.log("applicant detail  : ",json.data?.app_details[0]);
      }

      if(json.data?.app_details[2]?.stage === 3){
        console.log("contact detail : ",json.data?.app_details[2].stage_data);

        const data = json.data?.app_details[2].stage_data

        
     
        
      }

      if(json.data?.app_details[3]?.stage === 4){
        setRooftopSubmit(true)
        setRoofDetailbadge(true);
        const data = json.data?.app_details[3].stage_data;
        console.log("rooftop received : ",data);
         
      }

      if(json.data?.app_details[1]?.stage === 2){
        const data = json.data?.app_details[1].stage_data
       // console.log(" company detail data : ", json.data?.app_details[1].stage_data);
        setCompanyDetailbadge(true);
        setCompanyDetailSubmit(true);
       

      }

    }

  }


  const getState = async() => {
    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/state`;

    const json = await ApiHandler({"apiUrl":api, "authAccess":accessToken });

    console.log("state data : ",json);

    if(json){
      if(json.status == "Success"){
        setStateData(json.data);
      }
    }

  }

  React.useEffect(() => {
    getState()
  },[]);

  const getDistrict = async() => {

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/district`;
    const method = "POST";
    const body = { "discom_name":shortDist }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("form status : ",json);

    if(json){
      if(json.status === "Success"){
        setDistrictData(json.data);
      }
    }
  }

  React.useEffect(() => {
    getDistrict()
  },[shortDist])


  console.log("district : ",districtData);




  const TabLabelApplication = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setUserDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( userDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  const TabLabelContact = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setContactDetailBadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( contactDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  const TabLabelRooftop = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setRoofDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( roofDetailbadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  const TabLabelCompanyDetails = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setCompanyDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( companyDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  console.log("form data : ",formData);
  console.log(" state Array :  ",stateData);
  console.log("sate : ",setUserDetails.state , "state code : ",stateCode, " district : ",districtData);

  React.useEffect(() => {
   Array.isArray(formData) && formData.map((row) => {
    console.log("form data insider : ",row);

    if(row.stage === 1 ){
      setSubmitStage1(true)
      setUserDetailbadge(true);

      setUserDetails({
        name: row.stage_data.name,
        installation_address: row.stage_data.installation_address,
        address: row.stage_data.address,
        village: row.stage_data.village,
        city: row.stage_data.city,
        district: row.stage_data.district,
        discom : row.stage_data.discom,
        state: row.stage_data.state,
        pin: row.stage_data.pin,
        csc_vle: row.stage_data.csc_vle,
        csc_vleId: row.stage_data.csc_vleId
      });
    }

    else if(row.stage === 2){

      setCompanyDetailbadge(true);
      setCompanyDetailSubmit(true);

      setCompanyDetails({
        distribution_company:row.stage_data.distribution_company,
        division:row.stage_data.division,
        subDivision:row.stage_data.subDivision,
        consumerNo:row.stage_data.consumerNo,
        load:row.stage_data.load
      })
    }

    else if(row.stage === 4){

      setContactSubmit(true);
      setContactDetailBadge(true);

      setContactDetails({
        phone_no:row.stage_data.phone_no,
        email:row.stage_data.email,
        phone_no2:row.stage_data.phone_no2
      })
    }

    else if(row.stage === 3){

      setRooftopSubmit(true)
      setRoofDetailbadge(true);
      
      setRooftopData({
        state:row.stage_data.state,
        category:row.stage_data.category,
        plant_capacity:row.stage_data.plant_capacity,
        existing_capacity:row.stage_data.existing_capacity,
        latitude:row.stage_data.latitude,
        longitude:row.stage_data.longitude
      })
    }

   })

  },[formData])


  const handleRooftopLat = (e) => {
    const { name, value } = e.target;
    const latitudePattern = /^\d{0,2}(\.\d{0,8})?$/;

    if (name === 'latitude' && !latitudePattern.test(value)) {
      console.log("not valid regex input : ");
      return;
    }

    setRooftopSubmit(false);
    setRooftopData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRooftopLon = (e) => {
    const { name, value } = e.target;
    const latitudePattern = /^\d{0,2}(\.\d{0,8})?$/;

    if (name === 'longitude' && !latitudePattern.test(value)) {
      console.log("not valid regex input : ");
      return;
    }

    setRooftopSubmit(false);
    setRooftopData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogout = async() => {

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/logout`;
    const method = "POST";
    const body = {  }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("form status : ",json);

    if(json){
      if(json.status === "succes"){
        navigate("/" , { replace : true});
      }
    }
  }

  const submitFinalForm = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/submitForm`;
    const method = "POST";
    const body = {  }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("final form data : ", json );

    setProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message); 
      }
    }

    
  }

  const discomList = async() => {
    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/discom_list`;
    const method = "POST";
    const body = { "state_id":1 }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("final form data : ", json );

    setProgress(false);
    if(json){
      if(json.status_code === 200 ){
        setDiscomData(json.data);
      }

      if(json.status === "error"){
        setSnackEError(json.message);
        setSnackRightEOpen(true);
      }
    }
  }

  React.useEffect(() => {
    discomList()
    setTimeout(() => {
      console.log("discom data : ",discomData);
    },2000)
  },[])

  console.log("short dist name : ",shortDist);

  
  return (
    <Box className=" h-screen bg-[linear-gradient(to_top,_white,_#3FA2F6)]" sx={{ display:'flex', alignItems:'center' , flexDirection:'column' }} >


<Navbar shouldHideOnScroll>
      <NavbarBrand className=' w-[100%]' >
        <Box component="img" src={logo} sx={{ width: isXs ? "3.5rem" : "5rem" }} />
      </NavbarBrand>
      
      <NavbarContent justify="end">


      <Dropdown>
      <DropdownTrigger className=' cursor-pointer' >
       
          <Box sx={{ border:"1px solid white", borderRadius:2, display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding: isXs ? '0.2rem 0.5rem' : '0.5rem 1rem' }} >

          <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize: isXs ? "0.8rem" : "0.9rem" }}>{`${userDetails.name.substring(0,10)}`}..</Typography>
          <ArrowDropDownIcon/>
          </Box>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        <DropdownItem onClick={() => handleLogout()} key="delete" className="text-danger font-montserrat" color="danger" >
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>


        {/* <NavbarItem className="hidden lg:flex">
          <Link href="#">Login</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="primary" href="#" variant="flat">
            Sign Up
          </Button>
        </NavbarItem> */}
      </NavbarContent>
    </Navbar>

<div className=" flex items-center justify-center w-[95%] flex-col mt-10">
<Tabs color="primary" size='sm' fullWidth aria-label="Tabs radius" className=' ml-auto flex' >
  {
      formStatus === 2 ? null : 
  
        <Tab key="photos"  className={` font-montserrat ${ isXs ? 'w-[100%]'  : 'w-[90%]'  } `}  title={<TabLabelApplication title={ <div style={{ fontSize: isXs ? '0.8rem' : 'inherit' }}>
        Applicant's Details
         </div> } badgeCount={1} />} >
          <Card className=' mt-5' >
            <CardBody>
              <div className=' flex flex-col gap-y-4' >

              <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>
              <Input
              onChange={handleFormChange}
              value={userDetails.name}
              isRequired
              type="text"
              name="name"
              label="Enter Name"
              className=" w-[30rem]"
              color='default'
              />

            <Input
            onChange={handleFormChange}
            value={userDetails.installation_address}
              isRequired
              type="text"
              name="installation_address"
              label="Inst.. Address"
              className=" w-[30rem]"
              color='default'
              />

                </span>


                <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>
                <Input
              onChange={handleFormChange}
                value={userDetails.address}
              isRequired
              name="address"
              type="text"
              label="Enter Address"
              className=" w-[30rem]"
              color='default'
              
              />

          <Input
          onChange={handleFormChange}
            value={userDetails.village}
              isRequired
              name="village"
              type="text"
              label="Enter Village"
              className=" w-[30rem]"
              color='default'
              />

                </span>

                <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>
              <Input
              onChange={handleFormChange}
                value={userDetails.city}
              isRequired
              name="city"
              type="text"
              label="Enter City"
              className=" w-[30rem]"
              color='default'
              
              />

          {/* <Input
          onChange={handleFormChange}
            value={userDetails.district}
              isRequired
              type="text"
              name="district"
              label="Enter District"
              className=" w-[30rem]"
              color='default'
              /> */}

            <Select 
                onChange={handleFormChange}
                value={userDetails.state}
                  isRequired
                  defaultSelectedKeys={userDetails.state}
                  type="text"
                  name="state"
                  label={ !userDetails.state ? "Select State" : userDetails.state }
                  className=" w-[30rem]"
                  color='default'
                    >{
                     Array.isArray(stateData) && stateData.map((row) => (
                       <SelectItem key={row.state_name} onClick={() => setStateCode(row.sate_id)} value={userDetails.state} >{row.state_name}</SelectItem>
                     ))
                    }


                    </Select>

              

                </span>

                <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>
                  <Select 
                onChange={handleFormChange}
                value={userDetails.discom}
                  isRequired
                  defaultSelectedKeys={userDetails.discom}
                  type="text"
                  name="discom"
                  label={ !userDetails.discom ? "Select Discom" : userDetails.discom }
                  className=" w-[90%]"
                  color='default'
                 // onClick={(e) => setShortDist(e?.short_name)}
                    >{
                     Array.isArray(discomData) && discomData.map((row) => (
                       <SelectItem key={row?.discom_name} onClick={(e) => setShortDist(row?.short_name)} value={userDetails.discom } >{row.discom_name}</SelectItem>
                     ))
                    }
                    </Select>
                </span>



                <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>

                <Select 
                onChange={handleFormChange}
                value={userDetails.district}
                  isRequired
                  type="text"
                  name="district"
                  label={ !userDetails.district ? "Enter District" : userDetails.district}
                  className=" w-[30rem]"
                  color='default'
                    >
                      {
                        Array.isArray(districtData) && districtData.map((row) => (
                          <SelectItem key={row.disctict_name}
                          onClick={() => setDistrictCode(row.district_code)}  >{row.disctict_name}</SelectItem>
                        ))
                      }

                  </Select>

              {/* <Input
              onChange={handleFormChange}
                value={userDetails.state}
              isRequired
              name="state"
              type="text"
              label="Enter State"
              className=" w-[30rem]"
              color='default'
              
              /> */}

          <Input
          onChange={handleFormChange}
            value={userDetails.pin}
              isRequired
              name="pin"
              type="text"
              label="Enter Pin"
              className=" w-[30rem]"
              color='default'
              />

                </span>

                <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}`}>

              <Input
              onChange={handleFormChange}
                value={userDetails.csc_vle}
              isRequired
              name="csc_vle"
              type="text"
              label="Enter Csc_Vield"
              className=" w-[30rem]"
              color='default'
              
              />

          <Input
          onChange={handleFormChange}
            value={userDetails.csc_vleId}
              isRequired
              name="csc_vleId"
              type="text"
              label="Enter Csc-Vle"
              className=" w-[30rem]"
              color='default'
              />

                </span>

                <Button disabled={ emptyField.length > 0 || submitStage1 === true } onClick={() => submitApplicationForm()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>


              </div>
            </CardBody>
          </Card>  
        </Tab>

         }

        

         {
          formStatus === 2 ? null : 
         
        <Tab  key="music" className=' font-montserrat w-[90%]' title={<TabLabelCompanyDetails title=" Distribution Company Detail " badgeCount={1} />}  >
          <Card className=' mt-3' >
            <CardBody   >
            <div className=' flex flex-col gap-y-4' >

            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''} ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.distribution_company}
            isRequired
            type="text"
            name="distribution_company"
            label="Enter Distribution Company"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />

            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.division}
            isRequired
            type="text"
            name="division"
            label="Enter Division"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'
            />

            </span>


            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.subDivision}
            isRequired
            name="subDivision"
            type="text"
            label="Enter Sub Division"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />

            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.consumerNo}
            isRequired
            name="consumerNo"
            type="text"
            label="Enter Consumer Number"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'
            />

            </span>

            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.load}
            isRequired
            name="load"
            type="text"
            label="Enter Load"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />
            </span>

            <Button disabled={ emptyCompanyData.length > 0 || companyDetailBadge === true } onClick={() => submitCompanyData()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>
            </div>           
            </CardBody>
          </Card>  
        </Tab>

         }


         {
          formStatus === 2 ? null : 
         

        <Tab key="dance" className=' font-montserrat w-[90%]' title={<TabLabelRooftop title="Rooftop Details" badgeCount={1} />} >
          <Card className=' mt-3' >
            <CardBody   >
            <div className=' flex flex-col gap-y-4' >

            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleRooftop}
            value={rooftopData.state}
            isRequired
            type="text"
            name="state"
            label="Enter State"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />

            <Input
            onChange={handleRooftop}
            value={rooftopData.category}
            isRequired
            type="text"
            name="category"
            label="Enter Category"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'
            />

            </span>


            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleRooftop}
            value={rooftopData.plant_capacity}
            isRequired
            name="plant_capacity"
            type="text"
            label="Enter Plant Capacity"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />

            <Input
            onChange={handleRooftop}
            value={rooftopData.existing_capacity}
            isRequired
            name="existing_capacity"
            type="text"
            label="Enter Existing Capacity"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'
            />

            </span>

            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>
            <Input
            onChange={handleRooftopLat}
            value={rooftopData.latitude}
            isRequired
            name="latitude"
            type="text"
            label="Enter latitude"
            pattern="\d{0,2}(\.\d{0,8})?"
            title="Please enter a valid latitude (e.g., 28.12345678 or 56.52132569)"    
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'
            />

          <Input
            onChange={handleRooftopLon}
            value={rooftopData.longitude}
            isRequired
            name="longitude"
            type="text"
            label="Enter Longitude"
            className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
            color='default'

            />
            </span>

            <Button disabled={ emptyRooftopData.length > 0 || rooftopSubmit === true } onClick={() => submitRooftopData()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>
            </div>           
            </CardBody>
          </Card>  
        </Tab>
      }


      {
        formStatus === 2 ? null : 
      
        <Tab key="videos" className=' font-montserrat w-[90%]' title={<TabLabelContact title="Contact Details " badgeCount={1} />}  >
          <Card>
            <CardBody>
            {/* <div className=' flex flex-col items-center gap-y-4 mt-2' > */}

            <span className={`flex justify-evenly ${isXs ? 'gap-2' : ''}  ${isXs ? "flex-col" : "flex-row" } `}>

                      <Input
                      onChange={handleContact}
                      value={contactDetails.phone_no}
                      isRequired
                      name="phone_no"
                      type="text"
                      label="Enter Phone Number"
                      className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
                      color='default'
                      />


                      <Input
                      onChange={handleContact}
                      value={contactDetails.email}
                      isRequired
                      name="email"
                      type="text"
                      label="Enter Email"
                      className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
                      color='default'

                      />

                      <Input
                      onChange={handleContact}
                      value={contactDetails.phone_no2}
                      isRequired
                      name="phone_no2"
                      type="text"
                      label="Enter Phone Number2"
                      className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `}
                      color='default'
                      />

                      </span>

                      <Button className={` ${ isXs ? "w-[100%]" : "w-[30rem]" } `} disabled={ emptyContactDetails.length > 0 || contactSubmit === true } onClick={() => submitContactDetails()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>


                      {/* </div>             */}
                      </CardBody>
          </Card>  
        </Tab>

      }
            {
              formStatus >= 1 ? 
        <Tab key="submit form" className=' font-montserrat w-[100%]'  title="Save & Preview Form" >
          <Card>
            <CardBody>
            <div className=' flex flex-col items-center gap-y-4 mt-2' >

              {/* <Image
                    width={400}
                    alt="Preview and Save "
                    src={prevsave}
                  /> */}
                 <Box sx={{ width:"95%" }} >

                  <Box>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem' }} > Ranapay Applicant's Details </Typography>
                    {
                      Array.isArray(formData) && formData.map((row, index) => (
                        row?.stage ===1 ? 
                          <Paper elevation={12} sx={{ padding: isXs ? 0.5 : 0 , borderRadius:2  }} >
                            <span style={{ display:'grid', gridTemplateColumns: isXs ? "repeat(1,1fr)" : "repeat(2,1fr)", gridColumnGap:5 }} >
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400 , fontSize: isXs ? "0.8rem" : "1rem" }}  >Name : {row.stage_data.name} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} >Installation Address : {row.stage_data.installation_address }</Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Address : {row.stage_data.address } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Village : {row.stage_data.village} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > City : {row.stage_data.city } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > District : {row.stage_data.district} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > State : {row.stage_data.state} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Pin : { row.stage_data.pin } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > CSC Vield - {row.stage_data.csc_vle} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > CSC Vield ID- {row.stage_data.csc_vleId} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Discom - {row.stage_data.discom} </Typography>


                            </span>
                          </Paper>
                        :
                          row.stage === 2 ? <Box>

                        <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem', mt:4 }} > Distribution Company Details</Typography>
                              <Paper elevation={12} sx={{ padding:2, borderRadius:2, p:2 }} >
                            <span style={{ display:'grid', gridTemplateColumns: isXs ? "repeat(1,1fr)" : "repeat(2,1fr)", gridColumnGap:5 }} >
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }}  > Distribution Company : {row.stage_data.distribution_company} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Division : {row.stage_data.division }</Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Sub Division : {row.stage_data.subDivision } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Consumer  Number : {row.stage_data.consumerNo } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Load : {row.stage_data.load } </Typography>

                            </span>
                          </Paper>


                          </Box> : row.stage === 4 ? <Box>

                          <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem' , mt:4 }} > Applicant's Rooftop Details </Typography>
                                <Paper elevation={12} sx={{ padding:2, borderRadius:2, p:2 }} >
                              <span style={{ display:'grid', gridTemplateColumns: isXs ? "repeat(1,1fr)" : "repeat(2,1fr)", gridColumnGap:5 }} >

                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500, fontSize: isXs ? "0.8rem" : "1rem"  }}  > Phone Number : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.phone_no} </span>  </Typography>

                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500, fontSize: isXs ? "0.8rem" : "1rem" }} >  EMail : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.email } </span> </Typography>
                                                                
                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500, fontSize: isXs ? "0.8rem" : "1rem" }} > Alternate Number : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.phone_no2 } </span> {row.stage_data.phone_no2 } </Typography>
                                

                              </span>
                            </Paper>
                          {
                              progress ? <LinearProgress sx={{ mt:5 }} /> :
                            <Button onClick={ () => submitFinalForm() } disabled={ !row.stage === 1 || !row.stage === 2 || !row.stage === 3 || !row.stage === 4 || pendingStage !== 0  } variant='contained' fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, mt:5 }} > submit data </Button>
                          }


                            </Box> :
                            row.stage === 3 ? <Box>

                            <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem',  mt:4 }} > Applicant's Contact Details </Typography>
                                  <Paper elevation={12} sx={{ padding:2, borderRadius:2, p:2 }} >
                                <span style={{ display:'grid', gridTemplateColumns: isXs ? "repeat(1,1fr)" : "repeat(2,1fr)", gridColumnGap:5 }} >
                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }}  > State : {row.stage_data.state} </Typography>
  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Category : {row.stage_data.category }</Typography>
                                                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Plant Capacity : {row.stage_data.plant_capacity } </Typography>
                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }}  > Existing Capacity : {row.stage_data.existing_capacity} </Typography>
  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Latitude : {row.stage_data.latitude }</Typography>
                                                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400, fontSize: isXs ? "0.8rem" : "1rem" }} > Longitude : {row.stage_data.longitude } </Typography>
                                  
  
                                </span>


                              </Paper>



                        
  
                              </Box> :
                                    null
                      ))
                    }

                  </Box>
                 </Box>

                      </div>            
                      </CardBody>
          </Card>  
        </Tab> : null
            }


      </Tabs>
    </div>  

    
    {/* <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper> */}


              <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightOpen}
                onClose={handleRightSnackClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackClose} severity='success' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackMessage}</Alert> 
            </Snackbar>  
            

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightEOpen}
                onClose={handleRightSnackEClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackEClose} severity='error' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackEError}</Alert> 
            </Snackbar> 


    </Box>
  )
}

export default DashBoard