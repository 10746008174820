

export const ApiHandler = async({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess }) => {

    console.log("handleapi data : ",{"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess })
    
   const fetchData =  await fetch(apiUrl, {
    method:method,
    headers:{
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin":"*",
        "Accept":"application/json",
        "Authorization":`Bearer ${authAccess}`
    },
    body:JSON.stringify( bodyData )
   });

   console.log("fetchApi json : ", fetchData.status,fetchData.status == '500' );
   
   if(fetchData.status === 200){

    const json = await fetchData.json();

    console.log("login json : ",json.Status, "fetch data : ",fetchData);

       return  json ;
   }
       else if(fetchData.status === 500 || fetchData.status === 501 || fetchData.status === 502 || fetchData.status === 503 || fetchData.status === 400 || fetchData.status === 402 || fetchData.status === 404 || fetchData.status === 302 ){
        console.log("entered 500");
        window.alert("Technical Issue, We are fixing it")
            // setTimeout(() => {
            //     window.location.reload();
            // },500) 
       }

       else if(fetchData.status === 401 ){
        window.alert("UnAuthonticated.");
    
        // setTimeout(() => {
        //     window.location.replace(`${process.env.REACT_APP_UAPI_URL}`, {replace : true});
        // },1000)
       }

        else{
        // setTimeout(() => {
        //     window.location.reload(`${process.env.REACT_APP_UAPI_URL}`);
        // },1000)
    }

}