import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import solarIcon from "../images/solar-cell_icon.png"
import solarIcon2 from "../images/solar-electricity.png"
import globe from "../images/globe.png"
import { useTheme, useMediaQuery } from '@mui/material';

const Content2 = () => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (

      
    
    <Paper elevation={18} sx={{ width: isXs ? "93%":"85%", backgroundColor:'#96C9F4', borderRadius: isXs ? 1 : 4, mt:-3 }} >
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-between" }} >
      <Box component="img" src="https://urjadaily.com/wp-content/uploads/2022/01/The-Future-of-Solar-Panel-Monitoring-System-Market.jpg" sx={{ width:{ lg:"15rem", xs:"5rem" },  borderRadius: isXs ? 1 : 4 }} />
     
      <Box sx={{ width:"15rem", display:'flex', alignItems:'center', justifyContent:'center', gap:2 }} >
        {
          isXs ? null : 
        <Box component="img" src={ solarIcon } sx={{ width:"4rem", height:'3.5rem', color:"gray" }} />
        }
        <Box>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:600, fontSize: isXs ? "0.9rem":"2.3rem" , color:"#FAFFAF" }} > 18000+</Typography>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:500, fontSize: isXs ? "0.5rem" : "1rem", color:"#008DDA " }} > Installed Capacity </Typography>
        </Box>
      </Box>

      <Box sx={{ width:"15rem", display:'flex', alignItems:'center', justifyContent:'center', gap:2 }} >
      {
          isXs ? null : 
        <Box component="img" src={ solarIcon2 } sx={{ width:"4rem", height:'4rem', color:"gray" }} />
        }
        <Box>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:600, fontSize: isXs ? "0.9":"2.3rem", color:"#FAFFAF" }} > 12000+</Typography>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:500, fontSize: isXs ? "0.5rem" : "1rem", color:"#008DDA" }} > WTGS Installed </Typography>
        </Box>
      </Box>

      <Box sx={{ width:"15rem", display:'flex', alignItems:'center', justifyContent:'center', gap:2 }} >
      {
          isXs ? null : 
        <Box component="img" src={ globe } sx={{ width:"4rem", height:'4rem', color:"gray" }} />
        }
        <Box>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:600, fontSize: isXs ? "0.9":"2.3rem", color:"#FAFFAF" }} > 2450+ </Typography>
            <Typography sx={{ fontFamily:"Poppins", fontWeight:500, fontSize: isXs ? "0.5rem" : "1rem", color:"#008DDA" }} > CUSTOMERS </Typography>
        </Box>
      </Box>

    </Box>
  </Paper>  
  )
}

export default Content2