import { Alert, AppBar, Box, Button, Snackbar, Toolbar, Tooltip, Typography, Divider, TextField, LinearProgress } from '@mui/material'
import React from 'react';
import logo from "../images/Withe.png";
import { useLocation, useNavigate } from 'react-router-dom';
import noData from "../images/noData.jpg";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ranaLogo from "../images/Without-name.png"
import CloseIcon from '@mui/icons-material/Close';
import verifyNumber from "../images/verifyNumber.png";
import consumerImg from "../images/consumerKeyImg.png";
import register from "../images/rgistration.png";
import { ApiHandler } from './ApiHandler';
import login from "../images/login.png";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, user, Image } from "@nextui-org/react";
import {Tabs, Tab, Card, CardBody, Badge} from "@nextui-org/react";
import {Input} from "@nextui-org/react";
import {Select, SelectSection, SelectItem} from "@nextui-org/select";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme, useMediaQuery } from '@mui/material';



const StyledTableCell = styled(TableCell)(({ theme }) =>  ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.yellow,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
});


const AgentDashBoard = () => {

  const [openReg, setOpenReg] = React.useState(false);
  const [ regNumProg , setRegNumProg ] = React.useState(false);
  const [ regConKeyProg , setRegConsKeyProg] = React.useState(false);
  const [ loginOpenOtp , setLoginOpenOtp ] = React.useState(false);
  const  [ loginOtptoken , setLoginOtpToken ] = React.useState("");
  const [ regName , setRegName ] = React.useState("");
  const [ regEmail , setRegEmail ] = React.useState("");
  const [ regMobile , setRegMobile ] = React.useState("");
  const [ regKName , setKName ] = React.useState("");
  const [ enableKNo , setEnableKNo ] = React.useState(false);
  const  [ sendingOtp , setSendingOtp ] = React.useState(false);
  const [ activeRegNum , setActiveRegNum] = React.useState(false);
  const [ sessionId , setSessionId ] = React.useState("");
  const [ otpValue , setOtpValue ] = React.useState("");
  const [ progress, setProgress ] = React.useState(false);


  const [snackRightOpen, setSnackRightOpen ] = React.useState(false);
  const [snackMessage , setSnackMessage ] = React.useState('');
  const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
  const [snackEError , setSnackEError] = React.useState('');
  const [ regProgress , setRegProgress ] = React.useState(false);
  const [ enableRegSubmit , setEnableRegSubmit ] = React.useState(false);
  const [ openOtp , setOpenOtp ] = React.useState(false);

  const [formDetails , setFormDetails ] = React.useState(); 

  const [ openDash ,setOpenDash ] = React.useState(false);

  const [ agentTable , setAgentTable ] = React.useState();
  const [ formDialogue , setFormDialogue ] = React.useState(false); 

  const [ formStatus , setFormStatus ] = React.useState();
  const [ userDetailBadge , setUserDetailbadge ] = React.useState(false);
  const [ submitStage1 , setSubmitStage1 ] = React.useState(false);
  const [ rooftopSubmit , setRooftopSubmit ] = React.useState(false);
  const [ contactSubmit , setContactSubmit ] = React.useState(false);
  const [ companyDetailSubmit , setCompanyDetailSubmit ] = React.useState(false);
  const  [ stateData , setStateData ] = React.useState();
  const [ stateCode , setStateCode ] = React.useState("");
  const [ districtData , setDistrictData ] = React.useState();
  const [  districtCode , setDistrictCode ] =React.useState('');
  const [ formData , setFormData ] = React.useState();
  const [ pendingStage, setPendingStage ] = React.useState();

  const [emptyField, setEmptyField] = React.useState([]);
  const [ emptyCompanyData , setEmptyCompanyData ] = React.useState([]);
  const [ emptyRooftopData , setEmptyRooftopData ] = React.useState([]);
  const [emptyContactDetails , setEmptyContactDetails ] = React.useState([]);

  const [ roofDetailbadge , setRoofDetailbadge ] = React.useState(false);
  const [ companyDetailBadge , setCompanyDetailbadge ] = React.useState(false);
  const [ contactDetailBadge , setContactDetailBadge ] = React.useState(false);

  const [ discomData , setDiscomData ]  = React.useState();
  const  [ shortDist , setShortDist ] = React.useState("");


  
  const [ companyDetails ,setCompanyDetails ] = React.useState({
    distribution_company:"",
    division:"",
    subDivision:"",
    consumerNo:"",
    load:""
  });

  const [ rooftopData , setRooftopData ] = React.useState({
    state:"",
    category:"",
    plant_capacity:"",
    existing_capacity:"",
    latitude:"",
    longitude:""
  });

  const [ contactDetails , setContactDetails ] = React.useState({
    phone_no:"",
    email:"",
    phone_no2:""
  })

  const [ companyDetail , setCompanyDetail ] = React.useState(false);


  
  const [ userDetails , setUserDetails ] = React.useState({
    name:"",
    installation_address:"",
    address:"",
    village:"",
    city:"",
    district:"",
    state:"",
    pin:"",
    csc_vle:"",
    csc_vleId:""
  });

  const location = useLocation();
  const navigate = useNavigate();

  console.log("state code  : ",stateCode);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));  
  
  const accessToken = location.state?.data;
  const locData = location.state.date;
  const authAccess = locData.access_token;

  const submitApplicationForm = async() => {
    setProgress(true);
  
      console.log("user detail : ",userDetails);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/applicantForm` ;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":userDetails , "authAccess":authAccess });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setCompanyDetail(true);
        setUserDetailbadge(false);

        setTimeout(() => {
          getFormStatus()
        },[500])
        

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }  
  };

  const handleFormClose = () => {
    setFormDialogue(false);
  }


  const getFormStatus = async(e) => {
    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/getFormData`;
    const method = "POST";

    const bodyData = { "form_id":e }

    const json = await ApiHandler({"apiUrl":api, "bodyData":bodyData, "method":method, "authAccess":authAccess });

    console.log("form status data : ",json);

    if(json){

      if(json.status === "success"){

        setFormDetails(json.data.app_details);
         setFormDialogue(true);

        setFormData(json.data.app_details);
        setFormStatus(json.data?.form_status);
        setPendingStage(json.data?.pending_stage);

        if(json.data.pending_stage === 1 ){
          setUserDetails({});
          setCompanyDetails({});
          setRooftopData({});
          setContactDetails({});
        }

        else if( json.data.pending_stage === 2 ){
          setCompanyDetails({});
          setRooftopData({});
          setContactDetails({});
        }

        else if( json.data.pending_stage === 3 ){
          setRooftopData({});
          setContactDetails({});
        }

        else if( json.data.pending_stage === 4 ){
          setContactDetails({});
        }

      }

      if( json.data?.app_details[0]?.stage === 1 ){
        setSubmitStage1(true)
        setUserDetailbadge(true);
        const data = json.data?.app_details[0].stage_data;
     //   setFormData(json.data?.app_details);
      
        console.log("applicant detail  : ",json.data?.app_details[0]);
      }

      if(json.data?.app_details[2]?.stage === 3){
        console.log("contact detail : ",json.data?.app_details[2].stage_data);

        const data = json.data?.app_details[2].stage_data

        
     
        
      }

      if(json.data?.app_details[3]?.stage === 4){
        setRooftopSubmit(true)
        setRoofDetailbadge(true);
        const data = json.data?.app_details[3].stage_data;
        console.log("rooftop received : ",data);
         
      }

      if(json.data?.app_details[1]?.stage === 2){
        const data = json.data?.app_details[1].stage_data
       // console.log(" company detail data : ", json.data?.app_details[1].stage_data);
        setCompanyDetailbadge(true);
        setCompanyDetailSubmit(true);
       

      }

    }

  }
  

  React.useEffect(() => {
    getState()
    // getFormStatus()
  },[]);



  const getDistrict = async() => {

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/district`;
    const method = "POST";
    const body = { "discom_name":shortDist }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("form status : ",json);

    if(json){
      if(json.status === "Success"){
        setDistrictData(json.data);
      }
    }
  }

  React.useEffect(() => {
    getDistrict()
  },[shortDist])


  console.log("district : ",districtData);




  const TabLabelRooftop = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setRoofDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( roofDetailbadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };


  const handleContact = (e) => {
    const {name , value} = e.target;
    setContactSubmit(false);
    setContactDetails(prevState => ({
      ...prevState,
      [name]:value
    }));
  }


  const handleRooftopLat = (e) => {
    const { name, value } = e.target;
    const latitudePattern = /^\d{0,2}(\.\d{0,8})?$/;

    if (name === 'latitude' && !latitudePattern.test(value)) {
      console.log("not valid regex input : ");
      return;
    }

    setRooftopSubmit(false);
    setRooftopData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRooftop = (e) => {
    const {name , value} = e.target;

    setRooftopSubmit(false);

    setRooftopData(prevState => ({
      ...prevState,
      [ name ]: value
    }));
  }

  const handleFormChange = (e) => {
    const { name , value } = e.target;

    console.log("handle Form change : ", e.target );

    setSubmitStage1(false);

    setUserDetails(prevState => ({
      ...prevState,
      [ name ]:value
    }));
  };


  const TabLabelContact = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setContactDetailBadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( contactDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  

  const handleClose = () => {
    setOpenReg(false);
  };

  const handleName = (e) => {
    if(e){
      setRegName(e.target.value);
    }
    if(e.target.value?.length === 0 ){
      setRegName("");
      setRegEmail("");
      setRegMobile("");
      setEnableKNo(false)
      setKName("");
      setEnableKNo(false);
    }
  }

  const handleEmail = (e) => {
    if(e){    
    setRegEmail(e.target.value);

    const trimmedEmail = String(e.target.value)?.trim();
    const emailRegex =/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValid = emailRegex.test(trimmedEmail);
    console.log("isValid : ",isValid)
    if(isValid === true ){
      setActiveRegNum(true)
    }else if(isValid === false) {
      setActiveRegNum(false);
      setRegMobile("");
    }else{
      setActiveRegNum(false);
      setRegMobile("");
    }

      //console.log(isValid); // Log true if valid, false if not
      // return isValid;

    }
    if(e.target.value.length === 0){
      setRegMobile("");
      setEnableKNo(false)
      setKName("");
      setEnableKNo(false);
    }
  }

  const TabLabelApplication = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setUserDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( userDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  const TabLabelCompanyDetails = ({ title, badgeCount }) => {
    return  (
      <div onClick={() => setCompanyDetailbadge(false)}  style={{ display: 'flex', alignItems: 'center', margin:'1rem' }}>
        {title}
        &nbsp; &nbsp; &nbsp;
        {badgeCount > 0 && ( companyDetailBadge ?
          <Badge content={badgeCount} variant='shadow' color="success" className=' ml-8' /> : null
        )}
      </div>
    );
  };

  const handleCompanyDetails = (e) => {
    const {name , value} = e.target;
    setCompanyDetailSubmit(false);
    setCompanyDetails(prevState => ({
      ...prevState,
      [name]:value
    }));
  }


  const handleSendingOtpClose = ( )=> {
    setOpenOtp(false);
  } 
  
  const handleRightSnackClose = () => {
    setSnackRightOpen(false);
  }

  const handleOtpClose = ( )=> {
    setOpenOtp(false);
  }

  const handleMobileLogin = async() => {

    setRegNumProg(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verify_phone` ;
    const method = "POST";
    const bodyData = {"phone_no":regMobile }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("login json : ",json);

    setRegNumProg(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setEnableKNo(true);
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }

  React.useEffect(() => {
    if(regMobile.length === 10){
     
      handleMobileLogin();

      
    }else{
      console.log("target miss");
      //window.alert("entered agent dashboard")
    }
  },[regMobile]);


  const submitContactDetails = async() => {

    setProgress(true);

    console.log("contact details : ",contactDetails)


    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/contactDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "bodyData":contactDetails, "method":method, "authAccess":accessToken });

    console.log("form status : ",json);

    setProgress(false);

    if(json){
      
      if(json.Status === "Success"){
        setSnackRightOpen(true);
        setSnackMessage(json.Message);

        setTimeout(() => {
          getFormStatus()
      },[])
    }

    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message);
    }
    }
  }


  const submitFinalForm = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/submitForm`;
    const method = "POST";
    const body = {  }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("final form data : ", json );

    setProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message); 
      }
    }

    
  }


  const discomList = async() => {
    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/discom_list`;
    const method = "POST";
    const body = { "state_id":stateCode }

    const json = await ApiHandler({"apiUrl":api, "bodyData":body, "method":method, "authAccess":accessToken });

    console.log("final discom data : ", json );

    setProgress(false);
    if(json){
      if(json.status_code === 200 ){
        setDiscomData(json.data);
      }

      if(json.status === "error"){
        setSnackEError(json.message);
        setSnackRightEOpen(true);
      }
    }
  }

  React.useEffect(() => {
    discomList()
    setTimeout(() => {
      console.log("discom data : ",discomData);
    },2000)
  },[])





  const handleConsumer = async() => {

    setRegConsKeyProg(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/verify_consumerKey` ;
    const method = "POST";
    const bodyData = {"consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    console.log("login json : ",json);

    setRegConsKeyProg(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setEnableKNo(true);
        setEnableRegSubmit(true);
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }


  }


  const handleKName = (e) => {
    if(e){
      setKName(e.target.value);
    }
  }

  React.useEffect(() => {
    if(regKName.length === 10 ){
      console.log("mission accomplish");
      handleConsumer();
      return
    }else{
      console.log("mission pending");
      setEnableRegSubmit(false);
    }
  },[regKName])


  const registerUser = async() => {

    setRegProgress(true)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/register` ;
    const method = "POST";
    const bodyData = { "name":regName, "email":regEmail, "phone_no":regMobile, "consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":'' });

    setRegProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setOpenReg(false);
        setSendingOtp(true);
        getRegOtp();

        return;

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }
  }



  const handleMobile = (e) => {
    if(e){
      setRegMobile(e.target.value)
    }
    if(e.target.value?.length !== 10 ){
      setKName("");
      setEnableKNo(false)
      setEnableKNo(false);
    }
  }

  const handleRightSnackEClose = () => {
    setSnackRightEOpen(false);
  }




  // React.useEffect(() => {
  //   const role = locData?.role[0];
  //   console.log("role : ",role );

  //   if(role !== "agent"){
  //       navigate(-1, { replace : true })
  //   }

  // });


  const getRegOtp = async() => {

    setRegProgress(true)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/getOtp` ;
    const method = "POST";
    const bodyData = { "name":regName, "email":regEmail, "phone_no":regMobile, "consumer_key":regKName }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

    console.log("receive otp json : ",json);

    setRegProgress(false);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        setSendingOtp(false);
        setOpenOtp(true);
        setSessionId(json.data.session_Id);
        // setTimeout(() => {
        //   navigate("/dashboard")
        // },1000)


      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }




  const submitCompanyData = async() => {

    setProgress(true);

    console.log("company details ****** : ",companyDetail)

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/companyDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":companyDetails , "authAccess":accessToken });

    console.log("login json data : ",json);

    setProgress(false);

    
    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          getFormStatus()
        },[500])
        

      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }

  }




  const verifyOtp = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ; 
    const api = `${url}/verifyOtp` ;
    const method = "POST";
    const bodyData = { "phone_no":regMobile, "consumer_key":regKName, "otp":otpValue, "sessionId":sessionId }

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

    setProgress(false);

    console.log("verify otp json : ",json);

    if(json){
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
      
        setOpenDash(true);
        setOpenOtp(false);
        getTableData()
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
      }
    }
  }

  const handleRooftopLon = (e) => {
    const { name, value } = e.target;
    const latitudePattern = /^\d{0,2}(\.\d{0,8})?$/;

    if (name === 'longitude' && !latitudePattern.test(value)) {
      console.log("not valid regex input : ");
      return;
    }

    setRooftopSubmit(false);
    setRooftopData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitRooftopData = async() => {

    setProgress(true);

    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/rooftopDetailForm`;
    const method = "POST";

    const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":rooftopData , "authAccess":accessToken });

    console.log("login json data : ",json);

    setProgress(false);

    if(json){
      
      if(json.status === "success"){
        setSnackRightOpen(true);
        setSnackMessage(json.message);

        setTimeout(() => {
          //getFormStatus()
      },[500])
    }

    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message);
    }
    }

  }


  console.log("admin location data : ",locData);


  const getTableData = async() => {
    // https://uat.thebillpay.in/solarTest/getFormRow


    const url = process.env.REACT_APP_UAPI_URL ; 
    const api = `${url}/getFormRow` ;
    const method = "GET";

    const json = await ApiHandler({"apiUrl":api, "method":method, "authAccess":authAccess });

    setProgress(false);

    console.log("Table json : ",json);

    if(json){
      if(json.status === "success"){
        setAgentTable(json.data);
      }
    }

  }

  React.useEffect(() => {
    getTableData();
  },[])

  
  const getState = async() => {
    const url = process.env.REACT_APP_UAPI_URL ;
    const api = `${url}/state`;

    const json = await ApiHandler({"apiUrl":api, "authAccess":accessToken });

    console.log("state data : ",json);

    if(json){
      if(json.status == "Success"){
        setStateData(json.data);
      }
    }

  }


  // const getFormStatus = async(e) => {

  //   const url = process.env.REACT_APP_UAPI_URL ; 
  //   const api = `${url}/getFormStatus` ;
  //   const method = "POST";
  //   const bodyData = { "form_id":e }

  //   const json = await ApiHandler({"apiUrl":api, "method":method, "bodyData":bodyData ,"authAccess":authAccess });

  //   console.log("form data : ",json);

  //   setProgress(false);

  //   if(json){
  //     if(json.code === "200" ){
  //       setFormDetails(json.data.app_details);
  //       setFormDialogue(true);
        
  //       // window.alert(json.status + json.code)
  //     }
  //     if(json.status === "error"){
  //       setSnackEError(json.message);
  //       setSnackRightEOpen(true);
  //     }
  //   }
  // }

  React.useEffect(() => {
    Array.isArray(formData) && formData.map((row) => {
     console.log("form data insider : ",row);
 
     if(row.stage === 1 ){
       setSubmitStage1(true)
       setUserDetailbadge(true);
 
       setUserDetails({
         name: row.stage_data.name,
         installation_address: row.stage_data.installation_address,
         address: row.stage_data.address,
         village: row.stage_data.village,
         city: row.stage_data.city,
         district: row.stage_data.district,
         discom : row.stage_data.discom,
         state: row.stage_data.state,
         pin: row.stage_data.pin,
         csc_vle: row.stage_data.csc_vle,
         csc_vleId: row.stage_data.csc_vleId
       });
     }
 
     else if(row.stage === 2){
 
       setCompanyDetailbadge(true);
       setCompanyDetailSubmit(true);
 
       setCompanyDetails({
         distribution_company:row.stage_data.distribution_company,
         division:row.stage_data.division,
         subDivision:row.stage_data.subDivision,
         consumerNo:row.stage_data.consumerNo,
         load:row.stage_data.load
       })
     }
 
     else if(row.stage === 4){
 
       setContactSubmit(true);
       setContactDetailBadge(true);
 
       setContactDetails({
         phone_no:row.stage_data.phone_no,
         email:row.stage_data.email,
         phone_no2:row.stage_data.phone_no2
       })
     }
 
     else if(row.stage === 3){
 
       setRooftopSubmit(true)
       setRoofDetailbadge(true);
       
       setRooftopData({
         state:row.stage_data.state,
         category:row.stage_data.category,
         plant_capacity:row.stage_data.plant_capacity,
         existing_capacity:row.stage_data.existing_capacity,
         latitude:row.stage_data.latitude,
         longitude:row.stage_data.longitude
       })
     }
 
    })
 
   },[formData])

  console.log("form details : ",formDetails);

  return (
    <Box className="agendDash" sx={{ height:'100vh' }} >
      <AppBar position='sticky' sx={{ backgroundColor:"#3FA2F6" }} >
        <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:"space-between" }} >
          <Box component="img" src={logo} sx={{ width:"5rem" }} />
          <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1rem"}} >
            Welcome To Agent Pannel
          </Typography>
        </Toolbar>
      </AppBar>

      <Button onClick={() => setOpenReg(true)} startIcon={ <AddIcon/> } variant="contained" sx={{ marginTop:'auto', position:'fixed' , bottom:10, right:10, fontFamily:"montserrat", fontWeight:500 }} >Add User</Button>

    <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >

    {/* <Box component="img" src={ noData } sx={{ width:"25rem" }} /> */}

         <TableContainer elevation={16} component={Paper} sx={{ marginTop:0, width:"90%", mt:3, backgroundColor:"#0F67B1" }}>
                    <Table sx={{ minWidth: 800 ,backgroundColor:'white' }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{ backgroundColor:'#0F67B1' }} >
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }} align="center"  >Sr. No</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }} align="center"  >App ID</TableCell>
                            <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }}  align="center"> Consumer Key </TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }}   align="center"> Pending Stage </TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }}   align="center"> Form Status </TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }}   align="center">Form ID</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"0.9rem", xs:"0.7rem" } }}   align="center">Action</TableCell>

                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        Array.isArray(agentTable) && agentTable?.map((row, index) => (
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ index + 1}</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ row.app_id }</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ row.consumer_key }</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ row.pending_stage }</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ row.form_status }</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ row.form_id }</TableCell>
                                    <TableCell  align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  > <RemoveRedEyeIcon id="eyeIcon" onClick={() => getFormStatus(row.form_id) } sx={{ fontSize:"1.1rem" }} /> </TableCell>


                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>

      
    </Box>



    {/* ********************** Registeration Dialogue *********************** */}

    <Dialog
        open={openReg}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50rem",  // Set your width here
            },
          },
            zIndex:1500
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Register Please </Typography>
          
          <CloseIcon onClick={() => setOpenReg(false)} sx={{ backgroundColor:'#e03636',fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" } , borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:'center', flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }}} >
            <Box sx={{display:"flex", alignItems:'center' ,justifyContent:'center', flexDirection:'column'  }} >
              {
                regNumProg ? <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:'1.2rem', textTransform:"capitalize" }} > verifying number </Typography> : regConKeyProg ? 
                <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:'1.2rem', textTransform:"capitalize" }} > verifying consumer key </Typography>  : null

              }
            <Box component="img" src={ regNumProg ?  verifyNumber :  regConKeyProg ? consumerImg : register} sx={{ width:{lg:"25rem", xs:"12rem", sm:"22rem", md:"25rem" } }} />
            </Box>

          <Box sx={{ display:'grid', gridTemplateColumns:'repeat(1,1fr)', rowGap:2, columnGap:3, alignItems:'center', justifyContent:'center' }} >

            <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Name*</label> */}
              <TextField onChange={(e) => handleName(e) } value={ regName }  inputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Name*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>

              
              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Email</label> */}
              <TextField disabled={ !regName }  onChange={(e) => handleEmail(e)} value={ regEmail } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Email*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>

              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter Mobile Number*</label> */}
              <TextField disabled={ activeRegNum === false || regEmail.length === 0 } onChange={(e) => handleMobile(e)} value={ regMobile } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter Mobile Number*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>


              <Box sx={{ display:"flex", justifyContent:'center', flexDirection:'column', }} >
              {/* <label style={{ float:'left', textTransform:'capitalize', fontFamily:'Poppins', fontWeight:400, color:"#3b5af5", fontSize:"0.75rem" }} >Enter consumer Number</label> */}
              <TextField disabled={ enableKNo === false } onChange={(e) => handleKName(e)} value={ regKName } IinputProps={{ syle:{ fontFamily:'montserrat' } }} focused InputLabelProps={{ style:{fontFamily:"montserrat", fontWeight:500} }} label="Enter consumer Number*" variant='outlined'  sx={{ width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} />
              </Box>
          <Button onClick={() => registerUser()} size='large' disabled={ !regName || !regEmail || !regMobile || !regKName || enableRegSubmit !== true || regProgress }  variant='contained' sx={{ fontFamily:"Montserrat", mt:2, width:{lg:"20rem", md:"20rem", sm:"20rem", xs:"15rem"} }} >{regProgress ? "submitting data.." : "submit data" }</Button>
          </Box>
          </Box>
        </DialogContent>
      
      </Dialog>










       {/* ********************************** Sending otp Dialogue **************************** */}


    <Dialog
        open={sendingOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSendingOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{  width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box>
                  <label style={{ fontFamily:"Poppins", fontSize:'0.7rem', textTransform:"capitalize", color:"#7d7d7d" }} >Sending OTP to Your Regitered Number</label>
                    <LinearProgress fullWidth sx={{ marginTop:1 }} />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>





    {/* *********************** Register OTP Dialogue ******************************* */}





    <Dialog
        open={openOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{ width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
                  <label style={{ fontFamily:"Poppins", fontSize:'0.8rem', textTransform:"capitalize", color:"#7d7d7d" }} >Enter OTP Send To Register Number*</label>
                  <TextField value={ otpValue } onChange={(e) => setOtpValue(e.target.value)} sx={{ width:"15rem", marginTop:1 }} variant='outlined' />   
                {
                  progress ? <LinearProgress sx={{ marginTop:2,  width:"15rem" }} /> : 
              <Button onClick={() => verifyOtp()} disabled={ otpValue.length < 6 } sx={{ marginTop:2,  width:"15rem" }} variant='contained' size='small' fullWidth >Submit</Button>
                }
                </Box>
            </Box>
          

        </DialogContent>
      
      </Dialog>




      {/* ************************************ User Form Dialogue 1st stage ************************************* */}

                

      <Dialog
        open={openOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOtpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "40rem",  // Set your width here
            },
          },
          
        }}
      >
        <DialogTitle sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}  >
          <Box component='img' src={ranaLogo} sx={{ width:{lg:"4rem", xs:"2rem", sm:"4rem", md:"4rem"} }} />

          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.9rem", sm:"1.1rem", md:"1.2rem" } }}  > Welcome To RanaPay </Typography>
          
          <CloseIcon onClick={() => setOpenOtp(false)} sx={{ backgroundColor:'#e03636', fontSize:{lg:"1.5rem" , xs:"1rem", sm:"1.3rem", md:"1.5rem" }, borderRadius:'20%', color:'white', cursor:'pointer' }} />

        </DialogTitle>
        <Divider />
        <DialogContent  > 

          <Box sx={{ display:"flex", alignItems:'center' ,justifyContent:{xs:'space-between', lg:"center", md:"center", sm:'center'  }, flexDirection:{xs:"column", sm:"row", md:"row", lg:"row"} }} >

            <Box component="img" src={login} sx={{ width:{lg:"20rem", xs:"18rem" } }} />

      

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
                  <label style={{ fontFamily:"Poppins", fontSize:'0.8rem', textTransform:"capitalize", color:"#7d7d7d" }} >Enter OTP Send To Register Number*</label>
                  <TextField value={ otpValue } onChange={(e) => setOtpValue(e.target.value)} sx={{ width:"15rem", marginTop:1 }} variant='outlined' />   
                {
                  progress ? <LinearProgress sx={{ marginTop:2,  width:"15rem" }} /> : 
              <Button onClick={() => verifyOtp()} disabled={ otpValue.length < 6 } sx={{ marginTop:2,  width:"15rem" }} variant='contained' size='small' fullWidth >Submit</Button>
                }
                </Box>
            </Box>

            {/* <DashBoard/> */}
          

        </DialogContent>
      
      </Dialog>





      <Dialog
      fullWidth
        open={formDialogue}
        onClose={handleFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll='body'
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '90%', // Adjust the max height as needed
            maxWidth: '100vw',  // Adjust the max width as needed
          },
        }}

        
      >
        <CloseIcon onClick={() => setFormDialogue(false)} sx={{ position:"absolute", color:"red", right:0, cursor:'pointer' }} />
        {/* <DialogTitle position="static" sx={{ }} id="alert-dialog-title">
          {"Ranapay Solar Services Form."}
        </DialogTitle> */}
        <DialogContent sx={{ width:"100%", display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
        <div className=" flex items-center justify-center w-[95%] flex-col mt-2">
          <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"1.2rem", textAlign:'left', float:'left', mb:2 }} > Ranapay Solar Services Form.  </Typography>
        
        <Tabs color="primary" size='lg' fullWidth aria-label="Tabs radius" className=' ml-auto flex ' >
  {
      formStatus === 2 ? null : 
  
        <Tab key="photos"  className=' font-montserrat w-[100%]' title={<TabLabelApplication title="Applicant's Details" badgeCount={1} />} >
          <Card className=' mt-5' >
            <CardBody className=' w-[100%]' >
              <div className=' flex flex-col gap-y-4' >

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
              <Input
              onChange={handleFormChange}
                value={userDetails.name}
              isRequired
              type="text"
              name="name"
              label="Enter Name"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              
              />

          <Input
          onChange={handleFormChange}
            value={userDetails.installation_address}
              isRequired
              type="text"
              name="installation_address"
              label="Installation Address"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              />

                </span>


                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
              <Input
              onChange={handleFormChange}
                value={userDetails.address}
              isRequired
              name="address"
              type="text"
              label="Enter Address"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              
              />

          <Input
          onChange={handleFormChange}
            value={userDetails.village}
              isRequired
              name="village"
              type="text"
              label="Enter Village"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              />

                </span>

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
              <Input
              onChange={handleFormChange}
                value={userDetails.city}
              isRequired
              name="city"
              type="text"
              label="Enter City"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              
              />

          {/* <Input
          onChange={handleFormChange}
            value={userDetails.district}
              isRequired
              type="text"
              name="district"
              label="Enter District"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              /> */}

            <Select 
                onChange={handleFormChange}
                value={userDetails.state}
                  isRequired
                  defaultSelectedKeys={userDetails.state}
                  type="text"
                  name="state"
                  label={ !userDetails.state ? "Select State" : userDetails.state }
                  className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
                  color='default'
                    >{
                     Array.isArray(stateData) && stateData.map((row) => (
                       <SelectItem key={row.state_name} onClick={() => setStateCode( row.sate_id )} value={userDetails.state} >{row.state_name}</SelectItem>
                     ))
                    }


                    </Select>

              

                </span>

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
                  <Select 
                onChange={handleFormChange}
                value={userDetails.discom}
                  isRequired
                  defaultSelectedKeys={userDetails.discom}
                  type="text"
                  name="discom"
                  label="Select Discom"
                  className=" w-[90%]"
                  color='default'
                 // onClick={(e) => setShortDist(e?.short_name)}
                    >{
                     Array.isArray(discomData) && discomData.map((row) => (
                       <SelectItem key={row?.discom_name} onClick={(e) => setShortDist(row?.short_name)} value={userDetails.discom } >{row.discom_name}</SelectItem>
                     ))
                    }
                    </Select>
                </span>



                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >


                <Select 
                onChange={handleFormChange}
                value={userDetails.district}
                  isRequired
                  type="text"
                  name="district"
                  label={ !userDetails.district ? "Enter District" : userDetails.district}
                  className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
                  color='default'
                    >
                      {
                        Array.isArray(districtData) && districtData.map((row) => (
                          <SelectItem key={row.disctict_name}
                          onClick={() => setDistrictCode(row.district_code)}  >{row.disctict_name}</SelectItem>
                        ))
                      }

                  </Select>

              {/* <Input
              onChange={handleFormChange}
                value={userDetails.state}
              isRequired
              name="state"
              type="text"
              label="Enter State"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              
              /> */}

          <Input
          onChange={handleFormChange}
            value={userDetails.pin}
              isRequired
              name="pin"
              type="text"
              label="Enter Pin"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              />

                </span>

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
              <Input
              onChange={handleFormChange}
                value={userDetails.csc_vle}
              isRequired
              name="csc_vle"
              type="text"
              label="Enter Csc_Vield"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              
              />

          <Input
          onChange={handleFormChange}
            value={userDetails.csc_vleId}
              isRequired
              name="csc_vleId"
              type="text"
              label="Enter Csc-Vle"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
              color='default'
              />

                </span>

                <Button disabled={ emptyField.length > 0 || submitStage1 === true } onClick={() => submitApplicationForm()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>


              </div>
            </CardBody>
          </Card>  
        </Tab>

         }

        

         {
          formStatus === 2 ? null : 
         
        <Tab  key="music" className=' font-montserrat w-[90%]' title={<TabLabelCompanyDetails title=" Distribution Company Detail " badgeCount={1} />}  >
          <Card className=' mt-3' >
            <CardBody   >
            <div className=' flex flex-col gap-y-4' >

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.distribution_company}
            isRequired
            type="text"
            name="distribution_company"
            label="Distribution Company"
            className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
            color='default'

            />

            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.division}
            isRequired
            type="text"
            name="division"
            label="Enter Division"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}

            color='default'
            />

            </span>


            <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.subDivision}
            isRequired
            name="subDivision"
            type="text"
            label="Enter Sub Division"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}

            color='default'

            />

            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.consumerNo}
            isRequired
            name="consumerNo"
            type="text"
            label="Enter Consumer Number"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}

            color='default'
            />

            </span>

            <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleCompanyDetails}
            value={companyDetails.load}
            isRequired
            name="load"
            type="text"
            label="Enter Load"
              className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}

            color='default'

            />
            </span>

            <Button disabled={ emptyCompanyData.length > 0 || companyDetailBadge === true } onClick={() => submitCompanyData()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>
            </div>           
            </CardBody>
          </Card>  
        </Tab>

         }


         {
          formStatus === 2 ? null : 
         

          <Tab key="photos"  className=' font-montserrat w-[100%]' title={<TabLabelApplication title="Applicant's Details" badgeCount={1} />} >
          <Card className=' mt-3' >
            <CardBody   >
            <div className=' flex flex-col gap-y-4' >

                <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleRooftop}
            value={rooftopData.state}
            isRequired
            type="text"
            name="state"
            label="Enter State"
            className=" w-[30rem]"
            color='default'

            />

            <Input
            onChange={handleRooftop}
            value={rooftopData.category}
            isRequired
            type="text"
            name="category"
            label="Enter Category"
            className=" w-[30rem]"
            color='default'
            />

            </span>


            <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleRooftop}
            value={rooftopData.plant_capacity}
            isRequired
            name="plant_capacity"
            type="text"
            label="Enter Plant Capacity"
            className=" w-[30rem]"
            color='default'

            />

            <Input
            onChange={handleRooftop}
            value={rooftopData.existing_capacity}
            isRequired
            name="existing_capacity"
            type="text"
            label="Enter Existing Capacity"
            className=" w-[30rem]"
            color='default'
            />

            </span>

            <span className={`flex justify-evenly ${isXs ? "flex-col" : "flex-row" } ${ isXs ? " gap-y-4" : "" } `} >
            <Input
            onChange={handleRooftopLat}
            value={rooftopData.latitude}
            isRequired
            name="latitude"
            type="text"
            label="Enter latitude"
            pattern="\d{0,2}(\.\d{0,8})?"
            title="Please enter a valid latitude (e.g., 28.12345678 or 56.52132569)"    
            className=" w-[30rem]"
            color='default'
            />

          <Input
            onChange={handleRooftopLon}
            value={rooftopData.longitude}
            isRequired
            name="longitude"
            type="text"
            label="Enter Longitude"
            className=" w-[30rem]"
            color='default'

            />
            </span>

            <Button disabled={ emptyRooftopData.length > 0 || rooftopSubmit === true } onClick={() => submitRooftopData()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>
            </div>           
            </CardBody>
          </Card>  
        </Tab>
      }


      {
        formStatus === 2 ? null : 
      
        <Tab key="videos" className=' font-montserrat w-[50%]' title={<TabLabelContact title="Contact Details " badgeCount={1} />}  >
          <Card>
            <CardBody>
            <div className=' flex flex-col items-center gap-y-4 mt-2' >


                      <Input
                      onChange={handleContact}
                      value={contactDetails.phone_no}
                      isRequired
                      name="phone_no"
                      type="text"
                      label="Enter Phone Number"
                      className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
                      color='default'
                      />


                      <Input
                      onChange={handleContact}
                      value={contactDetails.email}
                      isRequired
                      name="email"
                      type="text"
                      label="Enter Email"
                      className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
                      color='default'

                      />

                      <Input
                      onChange={handleContact}
                      value={contactDetails.phone_no2}
                      isRequired
                      name="phone_no2"
                      type="text"
                      label="Enter Phone Number2"
                      className={` ${ isXs ? "w-[100&]" : "w-[30rem]"} `}
                      color='default'
                      />


                      <Button className=' w-[30rem]' disabled={ emptyContactDetails.length > 0 || contactSubmit === true } onClick={() => submitContactDetails()} sx={{ fontFamily:"montserrat", fontWeight:500, mt:2 }} color="primary" variant='contained' >Submit</Button>


                      </div>            
                      </CardBody>
                    </Card>  
                  </Tab>

               }
              {
              formStatus >= 1 ? 
        <Tab key="submit form" className=' font-montserrat'  title="Save & Preview Form" >
          <Card>
            <CardBody >
            <div className=' flex flex-col items-center mt-2 '>
              {/* <Image
                    width={400}
                    alt="Preview and Save "
                    src={prevsave}
                  /> */}
                 <Box>

                  <Box  >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem' }} > Ranapay Applicant's Details </Typography>
                    {
                      Array.isArray(formData) && formData.map((row, index) => (
                        row?.stage ===1 ? 
                          <Paper elevation={12} sx={{ padding:2, borderRadius:2 }} >
                            <span style={{ display:'grid', gridTemplateColumns:"repeat(2,1fr)", gridColumnGap:5 }} >
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}}  >Name : {row.stage_data.name} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} >Installation Address : {row.stage_data.installation_address }</Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Address : {row.stage_data.address } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Village : {row.stage_data.village} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > City : {row.stage_data.city } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > District : {row.stage_data.district} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > State : {row.stage_data.state} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Pin : { row.stage_data.pin } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > CSC Vield - {row.stage_data.csc_vle} </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > CSC Vield ID- {row.stage_data.csc_vleId} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Discom - {row.stage_data.discom} </Typography>


                            </span>
                          </Paper>
                        :
                          row.stage === 2 ? <Box>

                        <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem', mt:4 }} > Distribution Company Details</Typography>
                              <Paper elevation={12} sx={{ padding:2, borderRadius:2 }} >
                            <span style={{ display:'grid', gridTemplateColumns:"repeat(2,1fr)", gridColumnGap:5 }} >
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}}  > Distribution Company : {row.stage_data.distribution_company} </Typography>

                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Division : {row.stage_data.division }</Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Sub Division : {row.stage_data.subDivision } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Consumer  Number : {row.stage_data.consumerNo } </Typography>
                              
                              <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Load : {row.stage_data.load } </Typography>

                            </span>
                          </Paper>


                          </Box> : row.stage === 4 ? <Box>

                          <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem' , mt:4 }} > Applicant's Rooftop Details </Typography>
                                <Paper elevation={12} sx={{ padding:2, borderRadius:2}} >
                              <span style={{ display:'grid', gridTemplateColumns:"repeat(2,1fr)", gridColumnGap:5 }} >

                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500}}  > Phone Number : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.phone_no} </span>  </Typography>

                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500}} >  EMail : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.email } </span> </Typography>
                                                                
                                <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:500}} > Alternate Number : <span style={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > {row.stage_data.phone_no2 } </span> {row.stage_data.phone_no2 } </Typography>
                                

                              </span>
                            </Paper>
                          {
                              progress ? <LinearProgress sx={{ mt:5 }} /> :
                            <Button onClick={ () => submitFinalForm() } disabled={ !row.stage === 1 || !row.stage === 2 || !row.stage === 3 || !row.stage === 4 || pendingStage !== 0  } variant='contained' fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, mt:5 }} > submit data </Button>
                          }


                            </Box> :
                            row.stage === 3 ? <Box>

                            <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#0F67B1', textAlign:'center', mb:2, fontWeight:600, fontSize:'1.2rem',  mt:4 }} > Applicant's Contact Details </Typography>
                                  <Paper elevation={12} sx={{ padding:2, borderRadius:2 }} >
                                <span style={{ display:'grid', gridTemplateColumns:"repeat(2,1fr)", gridColumnGap:5 }} >
                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}}  > State : {row.stage_data.state} </Typography>
  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Category : {row.stage_data.category }</Typography>
                                                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Plant Capacity : {row.stage_data.plant_capacity } </Typography>
                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}}  > Existing Capacity : {row.stage_data.existing_capacity} </Typography>
  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Latitude : {row.stage_data.latitude }</Typography>
                                                                  
                                  <Typography sx={{ padding:1 , fontFamily:'montserrat', fontWeight:400}} > Longitude : {row.stage_data.longitude } </Typography>
                                  
                                </span>
                              </Paper> 
  
                              </Box> :
                                    null
                      ))
                    }

                  </Box>
                 </Box>

                      </div>            
                      </CardBody>
          </Card>  
        </Tab> : null
            }


      </Tabs>
    </div>  
          
        </DialogContent>
       
      </Dialog>




      <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightOpen}
                onClose={handleRightSnackClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackClose} severity='success' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackMessage}</Alert> 
            </Snackbar>  
            

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightEOpen}
                onClose={handleRightSnackEClose}
                autoHideDuration={3000}
                sx={{ zIndex:1550 }}
              >
             <Alert onClose={handleRightSnackEClose} severity='error' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackEError}</Alert> 
            </Snackbar> 

    </Box>
  )
}

export default AgentDashBoard