import { Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react';
import contactus from "../images/getInTouck.jpg";
import mailIcon from "../images/mail.png";
import phoneIcon from "../images/phone-call.png";
import locationIcon from "../images/getInTouch.jpg";
import { useTheme, useMediaQuery } from '@mui/material';


const ContactUs = () => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
        <Box className="contactUs" sx={{ marginTop:"8rem", }} >

            <Box sx={{ display:'flex', justifyContent:'space-evenly' }} >


        {
            isXs ? null :
            <Box>
                <Box component="img" src={contactus} sx={{ width:"25rem" }} />
            </Box>
        }

            <Box sx={{ }} >
            <Typography sx={{ fontFamily:"montserrat", fontWeight:800, fontSize:{lg:"3rem", md:"3rem", sm:"2.7rem", xs:"2rem" }, textAlign:'center', color:"#0F67B1" }} >Contact Us</Typography>
            <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#787878', fontSize:{ lg:"1rem", sm:"0.9rem", xs:"0.75rem" }, textAlign:'center', paddingLeft:0.5, paddingRight:0.5 }} >
             Reach us out with your mail, contact number or location mentioned below.
            </Typography>
            
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between',flexDirection: isXs ? "column" : "row" , gap:2, mt:5 }} >

                <Paper className="contact" elevation={12} sx={{ borderRadius:3 ,display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:{lg:"1rem 2rem", xs:"0.5rem 1.5rem" } }} >
                       
                    <Box sx={{ backgroundColor:"white", padding:"0.5rem 0.9rem", borderRadius:"100%" }} >
                    <Box component='img' src={mailIcon} sx={{ width: isXs ? "2.5rem" : "3.5rem" , textAlign:'center'  }} />
                    </Box>

                    <Box sx={{ mt:1 }} >
                        <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:"capitalize", color:"#0F67B1", fontSize: isXs ? "0.8rem" : "1rem"  , textAlign:'center' }} >mail your queries</Typography>
                         <Divider variant='middles'sx={{ mt:1, mb:1 }} />
                        <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize: isXs ? "0.75rem" : "1rem" }} > Mail us on info@Ranapay.in</Typography>

                    </Box>
                    
                </Paper>
                
                <Paper className="contact" elevation={12} sx={{ borderRadius:3 ,display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:{lg:"1rem 2rem", xs:"0.5rem 1.5rem" } }} >

                <Box sx={{ backgroundColor:"white", padding:"0.5rem 0.9rem", borderRadius:"100%" }} >
                <Box component='img' src={phoneIcon} sx={{ width: isXs ? "2.5rem" : "3.5rem" , textAlign:'center'  }} />
                </Box>

                <Box sx={{ mt:1 }} >
                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:"capitalize", color:"#0F67B1", fontSize: isXs ? "0.85rem" : "1rem", textAlign:'center' }} >get in touch</Typography>
                    <Divider variant='middles'sx={{ mt:1, mb:1 }} />
                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize: isXs ? "0.8rem" : "1rem" }} > 18002700900, 9918784000 </Typography>

                </Box>

                </Paper>
            </Box>
            </Box>
            </Box>

        </Box> 

)
}

export default ContactUs